import { useMemo, useState } from "react";
import { Column } from "..";
import "./style.scss";
import Tooltip from "../../../components/UI/Tooltip";

type Props<T> = {
    column: Column<T>;
    item: T;
    value: JSX.Element | null;
    topAlignTooltip?: boolean;
};

function TableCell<T>(props: Props<T>) {
    const [showTooltip, setShowTooltip] = useState(false);

    const tooltipText = useMemo(
        () => props.column.getTooltipText?.(props.item, props.column) || null,
        [props.column, props.item]
    );

    return (
        <>
            <td
                className="table-cell"
                style={{
                    width: props.column.width,
                    minWidth: props.column.width,
                    maxWidth: props.column.width,
                }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {tooltipText && showTooltip && (
                    <Tooltip
                        style={{
                            bottom: props.topAlignTooltip ? "100%" : "auto",
                            top: props.topAlignTooltip ? "auto" : "100%",
                            width: props.column.tooltipWidth,
                        }}
                    >
                        <p>{tooltipText}</p>
                    </Tooltip>
                )}
                {props.value}
            </td>
        </>
    );
}

export default TableCell;
