import {
    faAnglesDown,
    faAnglesUp,
    faAnglesUpDown,
    faArrowLeft,
    faArrowRight,
    faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "..";
import Button from "../../../components/buttons/Button";
import Modal from "../../../hoc/Modal";
import "./style.scss";

type Props<T> = {
    columns: Column<T>[];
    hasModalContent?: boolean;
    onHeaderPositionChange?: (column: Column<T>, newIndex: number) => void;
    noHeaderPositionChangeKeys?: string[];
};

function TableHeaders<T>(props: Props<T>) {
    const { onHeaderPositionChange } = props;
    const { t } = useTranslation();
    const [columnModalOpen, setColumnModalOpen] = useState<string | null>(null);

    const isIndexDisabled = useCallback(
        (column: Column<T>, direction: "left" | "right") => {
            const shownColumns = props.columns.filter(
                (c) =>
                    !c.hide &&
                    !props.noHeaderPositionChangeKeys?.includes(
                        c.key.toString()
                    )
            );

            const columnIndexInShownColumns = shownColumns.findIndex(
                (c) => c.key === column.key
            );

            if (direction === "left") {
                return columnIndexInShownColumns === 0;
            }

            if (direction === "right") {
                return columnIndexInShownColumns === shownColumns.length - 1;
            }
        },
        [props.columns, props.noHeaderPositionChangeKeys]
    );

    const headerPositionChangeHandler = useCallback(
        (column: Column<T>, direction: "left" | "right") => {
            if (!onHeaderPositionChange) return;

            const shownColumns = props.columns.filter(
                (c) =>
                    !c.hide &&
                    !props.noHeaderPositionChangeKeys?.includes(
                        c.key.toString()
                    )
            );

            const columnIndexInShownColumns = shownColumns.findIndex(
                (c) => c.key === column.key
            );

            if (direction === "left") {
                if (columnIndexInShownColumns === 0) return;
                const newIndex = props.columns.findIndex(
                    (c) =>
                        c.key ===
                        shownColumns[columnIndexInShownColumns - 1].key
                );
                onHeaderPositionChange(column, newIndex);
                return;
            }

            if (direction === "right") {
                if (columnIndexInShownColumns === shownColumns.length - 1)
                    return;
                const newIndex = props.columns.findIndex(
                    (c) =>
                        c.key ===
                        shownColumns[columnIndexInShownColumns + 1].key
                );
                onHeaderPositionChange(column, newIndex);
                return;
            }
        },
        [
            onHeaderPositionChange,
            props.columns,
            props.noHeaderPositionChangeKeys,
        ]
    );

    return (
        <thead className="table-headers">
            <tr>
                {props.columns.map((column, index) => {
                    if (column.hide) return null;

                    return (
                        <th key={`head-cell-${index}`}>
                            <div
                                className="table-head-wrapper text-xs"
                                onClick={() => column.onSort?.(column)}
                                style={{
                                    cursor: column.onSort
                                        ? "pointer"
                                        : undefined,
                                }}
                            >
                                {column.title}{" "}
                                {column.onSort && (
                                    <FontAwesomeIcon
                                        icon={
                                            column.sortMode === "asc"
                                                ? faAnglesUp
                                                : column.sortMode === "desc"
                                                ? faAnglesDown
                                                : faAnglesUpDown
                                        }
                                        size="sm"
                                    />
                                )}
                                {props.onHeaderPositionChange &&
                                    !props.noHeaderPositionChangeKeys?.includes(
                                        column.key.toString()
                                    ) && (
                                        <Modal
                                            buttonElement={(ref) => (
                                                <button
                                                    ref={ref}
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setColumnModalOpen(
                                                            column.key.toString()
                                                        );
                                                    }}
                                                    style={{
                                                        backgroundColor:
                                                            "transparent",
                                                        border: "none",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faEllipsisVertical
                                                        }
                                                        size="sm"
                                                        color="var(--color-neutral-500)"
                                                        fixedWidth
                                                    />
                                                </button>
                                            )}
                                            align="right"
                                            isOpen={
                                                columnModalOpen === column.key
                                            }
                                            onClose={() =>
                                                setColumnModalOpen(null)
                                            }
                                            style={{
                                                marginLeft: "auto",
                                            }}
                                        >
                                            <div className="column-modal">
                                                <section>
                                                    <Button
                                                        variant={"secondary"}
                                                        leadingIcon={
                                                            faArrowLeft
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            headerPositionChangeHandler(
                                                                column,
                                                                "left"
                                                            );
                                                        }}
                                                        disabled={isIndexDisabled(
                                                            column,
                                                            "left"
                                                        )}
                                                    />
                                                    <span>
                                                        {t(
                                                            "general.moveColumn"
                                                        )}
                                                    </span>
                                                    <Button
                                                        variant={"secondary"}
                                                        leadingIcon={
                                                            faArrowRight
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            headerPositionChangeHandler(
                                                                column,
                                                                "right"
                                                            );
                                                        }}
                                                        disabled={isIndexDisabled(
                                                            column,
                                                            "right"
                                                        )}
                                                    />
                                                </section>
                                            </div>
                                        </Modal>
                                    )}
                            </div>
                        </th>
                    );
                })}
                {props.hasModalContent && <th></th>}
            </tr>
        </thead>
    );
}

export default TableHeaders;
