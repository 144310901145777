import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../components/inputs/Dropdown";
import useManualOrderTransportOptions from "../../../../hooks/functionality/useManualOrderTransportOptions";
import { TransportForm } from "../../schemas";
import "../../style.scss";

type Props = {
    control: Control<TransportForm>;
};

function TransportInputForm(props: Props) {
    const { t } = useTranslation();
    const transportOptions = useManualOrderTransportOptions();

    return (
        <div className="fleet-manual-order-form">
            <p className="text-sm">{t("fleetManualOrder.transport.title")}</p>
            <Controller
                control={props.control}
                name="transportOption"
                render={({ field: { value, onChange } }) => (
                    <Dropdown
                        value={value || ""}
                        onSelect={({ value }) => onChange(value)}
                        options={transportOptions}
                        label={t("fleetManualOrder.transport.label")}
                    />
                )}
            />
        </div>
    );
}

export default TransportInputForm;
