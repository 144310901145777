import { faHourglassEnd } from "@fortawesome/pro-duotone-svg-icons";
import { faShare, faTag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../../components/inputs/Checkbox";
import Badge from "../../../components/UI/Badge";
import Table, { Column } from "../../../containers/Table";
import { StopSort } from "../../../hooks/functionality/useStopAndTourFilterAndSort";
import { StopTableData } from "../../../pages/BigVolume";
import { StopDraft } from "../../../shared/types/api";
import {
    CockpitStopColumn,
    cockpitStopColumns,
} from "../../../shared/types/internal";
import { ReduxState } from "../../../shared/types/redux";
import { convertToNumberWithSpaces } from "../../../shared/utility/misc";
import { getStopLocationText } from "../../../shared/utility/stop-draft";
import { setStopColumns } from "../../../store/slices/cockpit";
import "./style.scss";

type Props = {
    data: StopTableData[];
    onRowClick: (item: StopTableData) => void;

    //Mark all props
    isMarkingAll: boolean;
    onMarkingAllChange: (value: boolean) => void;
    maxMarkAllStops: number;

    //Selected stops
    selectedStops: StopDraft[];
    onSelectedStopsChange: (
        value: boolean,
        stops: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }
    ) => void;

    //sorting
    activeSort: StopSort;
    onSortChange: (sort: StopSort) => void;

    sectionsSize: "quarter" | "half" | "full";
};

const hourGlassIconStyle = {
    "--fa-primary-color": "var(--color-neutral-900)",
    "--fa-primary-opacity": "1",
    "--fa-secondary-color": "var(--color-neutral-400)",
    "--fa-secondary-opacity": "1",
    padding: "12px 8px",
};

function StopTable(props: Props) {
    const { t } = useTranslation();
    const stopColumnsSettings = useSelector(
        (state: ReduxState) => state.cockpit.stopColumns
    );
    const dispatch = useDispatch();

    const {
        onMarkingAllChange,
        onRowClick,
        onSelectedStopsChange,
        onSortChange,
    } = props;

    const sortedCockpitStopColumns = useMemo(() => {
        const cloned = [...cockpitStopColumns];
        return cloned.sort((a, b) => {
            const { position: aPosition } = stopColumnsSettings[a];
            const { position: bPosition } = stopColumnsSettings[b];

            return aPosition - bPosition;
        });
    }, [stopColumnsSettings]);

    const updateCockpitColumnsHandler = useCallback(
        (column: CockpitStopColumn, newIndex: number) => {
            const newStopColumnOrder = [...sortedCockpitStopColumns];

            const oldIndex = newStopColumnOrder.indexOf(column);
            if (oldIndex === -1) return;

            newStopColumnOrder.splice(
                newIndex,
                0,
                newStopColumnOrder.splice(oldIndex, 1)[0]
            );

            const newStopColumnsSettings: Record<
                CockpitStopColumn,
                { position: number; show: boolean }
            > = { ...stopColumnsSettings };
            for (let i = 0; i < newStopColumnOrder.length; i++) {
                const column = newStopColumnOrder[i];
                const currentSetting = stopColumnsSettings[column];
                newStopColumnsSettings[column] = {
                    ...currentSetting,
                    position: i,
                };
            }

            dispatch(setStopColumns(newStopColumnsSettings));
        },
        [dispatch, sortedCockpitStopColumns, stopColumnsSettings]
    );

    const columns = useMemo(() => {
        const allColumns: (Omit<Column<StopTableData>, "key"> & {
            key: CockpitStopColumn;
        })[] = [
            {
                key: "id",
                width: "50px",
                title: (
                    <Checkbox
                        disabled={props.data.length > props.maxMarkAllStops}
                        checked={props.isMarkingAll}
                        onChange={onMarkingAllChange}
                        style={{
                            padding: "6px",
                        }}
                    />
                ),
                render: ({ stops }) => {
                    if (
                        stops?.pickup.origin_location &&
                        !stops.pickup.move_accepted_at
                    ) {
                        return (
                            <FontAwesomeIcon
                                icon={faHourglassEnd}
                                size="lg"
                                style={
                                    hourGlassIconStyle as React.CSSProperties
                                }
                                fixedWidth
                                title={t("bigVolume.requestedStopHover", {
                                    orginLocation:
                                        stops.pickup.origin_location.name,
                                })}
                            />
                        );
                    }

                    return (
                        <Checkbox
                            checked={props.selectedStops.some(
                                (s) => s.id === stops?.pickup.id
                            )}
                            onChange={(newChecked) =>
                                stops
                                    ? onSelectedStopsChange(newChecked, stops)
                                    : () => {}
                            }
                            disabled={!stops}
                            style={{
                                padding: "12px 6px",
                            }}
                        />
                    );
                },
            },
            {
                key: "from",
                width: "150px",
                title: t("bigVolume.loading"),
                getValue: ({ stops, orderDraft }) =>
                    stops
                        ? getStopLocationText(stops.pickup)
                        : orderDraft?.from_location || "-",
                getTextColor: ({ stops, orderDraft }) =>
                    !stops && !orderDraft?.from_location
                        ? "var(--color-red-600)"
                        : undefined,
                sortMode:
                    props.activeSort === "from-asc"
                        ? "asc"
                        : props.activeSort === "from-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "from-asc"
                            ? "from-desc"
                            : "from-asc"
                    ),
                getTooltipText: ({ stops, orderDraft }) =>
                    stops?.pickup.to_location ||
                    orderDraft?.from_location ||
                    undefined,
            },
            {
                key: "to",
                width: "150px",
                title: t("bigVolume.unloading"),
                getValue: ({ stops, orderDraft }) =>
                    stops
                        ? getStopLocationText(stops.dropoff)
                        : orderDraft?.to_location || "-",
                getTextColor: ({ stops, orderDraft }) =>
                    !stops && !orderDraft?.to_location
                        ? "var(--color-red-600)"
                        : undefined,
                sortMode:
                    props.activeSort === "to-asc"
                        ? "asc"
                        : props.activeSort === "to-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "to-asc" ? "to-desc" : "to-asc"
                    ),
                getTooltipText: ({ stops, orderDraft }) =>
                    stops?.dropoff.to_location ||
                    orderDraft?.from_location ||
                    undefined,
            },
            {
                key: "contact",
                width: "130px",
                title: t("bigVolume.contact"),
                getValue: ({ stops, orderDraft }) =>
                    stops?.dropoff.contact?.name ||
                    stops?.pickup.contact?.name ||
                    orderDraft?.dropoff_contact?.name ||
                    orderDraft?.pickup_contact?.name ||
                    "-",
                sortMode:
                    props.activeSort === "contact-asc"
                        ? "asc"
                        : props.activeSort === "contact-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "contact-desc"
                            ? "contact-asc"
                            : "contact-desc"
                    ),
            },
            {
                key: "weight",
                width: "100px",
                title: t("bigVolume.weight"),
                getValue: ({ stops, orderDraft }) =>
                    stops?.pickup.weight_kg
                        ? convertToNumberWithSpaces(
                              stops.pickup.weight_kg,
                              "kg"
                          )
                        : orderDraft?.weight_kg
                        ? convertToNumberWithSpaces(orderDraft.weight_kg, "kg")
                        : "-",
                getTextColor: ({ stops, orderDraft }) =>
                    !stops && !orderDraft?.weight_kg
                        ? "var(--color-red-600)"
                        : undefined,
                sortMode:
                    props.activeSort === "weight-asc"
                        ? "asc"
                        : props.activeSort === "weight-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "weight-desc"
                            ? "weight-asc"
                            : "weight-desc"
                    ),
            },

            {
                key: "dropoff-time",
                width: "80px",
                title: t("bigVolume.dropoffTime"),
                getValue: ({ stops }) =>
                    stops?.dropoff.time_tooltip
                        ? stops?.dropoff.time_tooltip
                        : "-",
                sortMode:
                    props.activeSort === "dropoff-time-asc"
                        ? "asc"
                        : props.activeSort === "dropoff-time-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "dropoff-time-desc"
                            ? "dropoff-time-asc"
                            : "dropoff-time-desc"
                    ),
            },
            {
                key: "tags",
                width: "150px",
                title: t("bigVolume.tags"),
                render: ({ stops, orderDraft }) =>
                    stops?.pickup.tags || orderDraft?.tags ? (
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "4px",
                                padding: "4px 0",
                            }}
                        >
                            {stops?.pickup.order?.assigned_location_id && (
                                <Badge
                                    title={t("bigVolume.fodOrder")}
                                    variant="success"
                                    icon={faShare}
                                />
                            )}
                            {stops?.pickup.origin_location?.name && (
                                <Badge
                                    title={t("popup.stopsInfo.assignedFrom", {
                                        orginLocation:
                                            stops?.pickup.origin_location?.name,
                                    })}
                                    variant="success"
                                    icon={faShare}
                                />
                            )}
                            {(stops?.pickup.tags || orderDraft?.tags || []).map(
                                (tag) => (
                                    <Badge
                                        key={tag}
                                        title={tag}
                                        variant="neutral"
                                        icon={faTag}
                                    />
                                )
                            )}
                        </div>
                    ) : (
                        <p>-</p>
                    ),
                sortMode:
                    props.activeSort === "tags-asc"
                        ? "asc"
                        : props.activeSort === "tags-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "tags-desc"
                            ? "tags-asc"
                            : "tags-desc"
                    ),
            },
            {
                key: "ordernumber",
                width: "80px",
                title: t("bigVolume.orderNumber"),
                getValue: ({ stops, orderDraft }) =>
                    stops?.pickup.order_number
                        ? stops?.pickup.order_number
                        : orderDraft?.origin_order_number || "-",
                sortMode:
                    props.activeSort === "ordernumber-asc"
                        ? "asc"
                        : props.activeSort === "ordernumber-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "ordernumber-desc"
                            ? "ordernumber-asc"
                            : "ordernumber-desc"
                    ),
            },
            {
                key: "cargo-content",
                width: "170px",
                title: t("bigVolume.cargoContent"),
                getValue: ({ stops, orderDraft }) =>
                    stops?.pickup.cargo_content ||
                    orderDraft?.cargo_content ||
                    "-",
                sortMode:
                    props.activeSort === "cargo-content-asc"
                        ? "asc"
                        : props.activeSort === "cargo-content-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "cargo-content-desc"
                            ? "cargo-content-asc"
                            : "cargo-content-desc"
                    ),
                getTooltipText: ({ stops, orderDraft }) =>
                    stops?.pickup.cargo_content ||
                    orderDraft?.cargo_content ||
                    undefined,
                tooltipWidth: "200%",
            },
            {
                key: "driver-instructions",
                width: "170px",
                title: t("bigVolume.driverInstructions"),
                getValue: ({ stops, orderDraft }) =>
                    stops?.pickup.driver_instructions ||
                    orderDraft?.driver_instructions ||
                    "-",
                sortMode:
                    props.activeSort === "driver-instructions-asc"
                        ? "asc"
                        : props.activeSort === "driver-instructions-desc"
                        ? "desc"
                        : null,
                onSort: () =>
                    onSortChange(
                        props.activeSort === "driver-instructions-desc"
                            ? "driver-instructions-asc"
                            : "driver-instructions-desc"
                    ),
                getTooltipText: ({ stops, orderDraft }) =>
                    stops?.pickup.driver_instructions ||
                    orderDraft?.driver_instructions ||
                    undefined,
                tooltipWidth: "200%",
            },
        ];

        const filteredColumns = allColumns.map((c) => ({
            ...c,
            hide: !stopColumnsSettings[c.key].show,
        }));

        return filteredColumns.sort((a, b) => {
            const { position: aPosition } = stopColumnsSettings[a.key];
            const { position: bPosition } = stopColumnsSettings[b.key];
            return aPosition - bPosition;
        });
    }, [
        onMarkingAllChange,
        onSelectedStopsChange,
        onSortChange,
        props.activeSort,
        props.data.length,
        props.isMarkingAll,
        props.maxMarkAllStops,
        props.selectedStops,
        stopColumnsSettings,
        t,
    ]);

    return (
        <Table
            data={props.data}
            emptyStateLabel={t("bigVolume.emptyUnhandledStopsLabel")}
            onRowClick={onRowClick}
            maxHeight={
                props.sectionsSize === "quarter"
                    ? "var(--section-height)"
                    : "var(--section-height-full)"
            }
            columns={columns}
            onHeaderPositionChange={(c, newIndex) =>
                updateCockpitColumnsHandler(
                    c.key as CockpitStopColumn,
                    newIndex
                )
            }
            noHeaderPositionChangeKeys={["id"]}
        />
    );
}

export default StopTable;
