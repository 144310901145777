import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddressSearch from "../../../../components/inputs/AddressSearch";
import DateInput from "../../../../components/inputs/DateInput";
import Input from "../../../../components/inputs/Input";
import { DeliveryForm } from "../../schemas";
import "../../style.scss";

type Props = {
    control: Control<DeliveryForm>;
};

function DeliveryInputForm(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="fleet-manual-order-form">
            <p className="text-sm">{t("fleetManualOrder.delivery.title")}</p>

            <Controller
                control={props.control}
                name="fromAddress"
                render={({ field: { value, onChange } }) => (
                    <AddressSearch
                        value={value}
                        onChange={onChange}
                        label={t("createOrder.fromAddressLabel")}
                        width="100%"
                        type="pickup"
                        preselectedAddress={value}
                    />
                )}
            />

            <Controller
                control={props.control}
                name="toAddress"
                render={({ field: { value, onChange } }) => (
                    <AddressSearch
                        value={value}
                        onChange={onChange}
                        label={t("createOrder.toAddressLabel")}
                        width="100%"
                        type="dropoff"
                        preselectedAddress={value}
                    />
                )}
            />

            <Controller
                name="date"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                    <DateInput
                        label={t("fleetManualOrder.delivery.dateLabel") + "*"}
                        value={value}
                        onChange={onChange}
                        showAllDates
                        ignoreUnselectableDates
                        width="100%"
                    />
                )}
            />

            <Controller
                name="email"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                    <Input
                        type="email"
                        value={value}
                        onChange={onChange}
                        label={t("fleetManualOrder.delivery.emailLabel") + "*"}
                        style={{ width: "100%" }}
                    />
                )}
            />

            <Controller
                name="dropoffPhone"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                    <Input
                        type="tel"
                        value={value}
                        onChange={onChange}
                        label={t("createOrder.phoneLabel")}
                        style={{ width: "100%" }}
                        name="phone"
                    />
                )}
            />
        </div>
    );
}

export default DeliveryInputForm;
