import { motion } from "framer-motion";
import "./style.scss";

type Props = {
    style?: React.CSSProperties;
    children: React.ReactNode;
};

function Tooltip(props: Props) {
    const motionVariants = {
        initial: { opacity: 0, scale: 0, y: 10 },
        animate: { opacity: 1, scale: 1, y: 0 },
        exit: { opacity: 0, scale: 0, y: 0 },
    };

    return (
        <motion.div
            variants={motionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="tooltip"
            style={props.style}
        >
            {props.children}
        </motion.div>
    );
}

export default Tooltip;
