import { DriverForm } from "../schemas/form";
import axios from "../shared/services/axios";
import { Driver, FuelType } from "../shared/types/api";

export function getDrivers() {
    return axios.get<Driver[]>("/v2/drivers");
}

export function inviteDriver(data: DriverForm) {
    return axios.post("/driver/invite", {
        phone: data.phone,
        hourly_cost: data.hourlyCost ? +data.hourlyCost : null,
        fuel_type_id: data.fuelTypeId,
        fuel_consumption: data.fuelConsumption ? +data.fuelConsumption : null,
        first_name: data.firstName,
        last_name: data.lastName,
    });
}

export function updateDriver({ id, data }: { id: number; data: DriverForm }) {
    return axios.post("/driver", {
        id,
        first_name: data.firstName,
        last_name: data.lastName,
        hourly_cost: data.hourlyCost ? +data.hourlyCost : null,
        enabled: data.enabled,
        fuel_type_id: data.fuelTypeId,
        fuel_consumption: data.fuelConsumption ? +data.fuelConsumption : null,
    });
}

export function getFuelTypes() {
    return axios.get<FuelType[]>("/fuel_types");
}

export function deleteDriver(driverId: number) {
    return axios.delete(`/driver/${driverId}`);
}
