import { useQuery } from "@tanstack/react-query";
import { getOrder } from "../../api/order";

function useOrder({ orderId, disable }: { orderId: number; disable: boolean }) {
    const {
        data: order,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["order", orderId] as const,
        queryFn: async ({ queryKey: [, orderId] }) => {
            const res = await getOrder(orderId);
            return res.data;
        },
        enabled: !disable,
    });

    switch (status) {
        case "success":
            return {
                order,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                order,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                order,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useOrder;
