import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ZodError } from "zod";
import { shareOrderEmail, shareOrderPublic } from "../../api/order";
import IconOLD from "../../components/UI/IconOLD";
import Button from "../../components/buttons/Button";
import IconButton from "../../components/buttons/IconButton";
import Input from "../../components/inputs/Input";
import Confetti from "../../components/widgets/Confetti";
import OrderStatusTimeline from "../../components/widgets/OrderStatusTimeline";
import Popup from "../../hoc/Popup";
import { ShareOrderForm, shareOrderForm } from "../../schemas/form";
import { onFormError } from "../../shared/utility/misc";
import { copyPublicOrderUrl } from "../../shared/utility/order";
import { ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    orderId?: number;
    showPopup: boolean;
    onClose: () => void;
};

function OrderCreatedPopup(props: Props) {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        reset: resetInputs,
    } = useForm<ShareOrderForm>({
        resolver: zodResolver(shareOrderForm),
        defaultValues: {
            email: "",
        },
    });

    const [hasCopiedShareLink, setHasCopiedShareLink] = useState(false);

    const { mutate: shareOrderHandler, isPending: isSharing } = useMutation({
        mutationFn: async ({
            orderId,
            data: { email },
        }: {
            orderId: number;
            data: ShareOrderForm;
        }) => {
            await shareOrderEmail({
                orderId,
                email,
            });
        },
        onSuccess: () => {
            toast.success(t("successMessage.orderShared"));
            resetInputs();
        },
        onError: () => {
            toast.error(t("errorMessage.shareOrderError"));
        },
    });

    const { mutate: copyOrderLinkHandler, isPending: isCopying } = useMutation({
        mutationFn: async (orderId: number) => {
            const { data } = await shareOrderPublic(orderId);

            await copyPublicOrderUrl(data.public_id);
        },
        onSuccess: () => {
            toast.success(t("successMessage.orderUrlCopy"));
            setHasCopiedShareLink(true);
        },
        onError: (error) => {
            if (error instanceof ZodError) {
                toast.error(t(error.issues[0].message));
                return;
            }
            toast.error(t("errorMessage.shareOrderError"));
        },
    });

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.orderCreated.title")}
        >
            <div className="order-created-popup">
                <Confetti run={props.showPopup} />
                <header>
                    <div className="check-wrapper">
                        <IconOLD type="check" />
                    </div>
                    <p className="text-sm">{t("popup.orderCreated.header")}</p>
                </header>
                <p className="order-explanation text-xs">
                    {t("popup.orderCreated.whatHappens")}
                </p>
                <OrderStatusTimeline activeStatus="created" order={null} />
                {props.orderId && (
                    <section className="share-section">
                        <div className="share-wrapper">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        type="email"
                                        value={value}
                                        onChange={onChange}
                                        label={t(
                                            "popup.orderInfo.shareEmailLabel"
                                        )}
                                        placeholder={t(
                                            "popup.orderInfo.shareEmailPlaceholder"
                                        )}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            />
                            <Button
                                label={t("popup.orderInfo.shareButtonLabel")}
                                variant="secondary"
                                onClick={handleSubmit(
                                    (data) =>
                                        shareOrderHandler({
                                            orderId: props.orderId!,
                                            data,
                                        }),
                                    onFormError
                                )}
                            />
                        </div>
                        <IconButton
                            icon={hasCopiedShareLink ? "check" : "copy"}
                            style={
                                hasCopiedShareLink
                                    ? {
                                          color: "var(--text-color-alt)",
                                      }
                                    : undefined
                            }
                            label={t("popup.orderInfo.copyOrderLinkLabel")}
                            onClick={() => copyOrderLinkHandler(props.orderId!)}
                            isLoading={isCopying}
                        />
                    </section>
                )}
                <div className="contact text-sm">
                    <span>{t("popup.orderCreated.question")}</span>
                    <a href="tel:010-880 07 84">010-880 07 84</a>
                    <a href="mailto:help@alrik.com">help@alrik.com</a>
                </div>
                <div className="bottom">
                    <Button
                        label={t("popup.orderCreated.newOrderLinkLabel")}
                        variant="primary"
                        onClick={props.onClose}
                        style={{ width: "50%" }}
                    />
                    <NavLink to={ROUTE.ActiveOrders} style={{ width: "50%" }}>
                        <Button
                            label={t("popup.orderCreated.ordersLinkLabel")}
                            variant="secondary"
                            style={{ width: "100%" }}
                            isLoading={isSharing}
                        />
                    </NavLink>
                </div>
            </div>
        </Popup>
    );
}

export default OrderCreatedPopup;
