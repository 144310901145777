import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/Button";
import TextButton from "../../../components/buttons/TextButton";
import Checkbox from "../../../components/inputs/Checkbox";
import Modal from "../../../hoc/Modal";
import {
    CockpitStopColumn,
    cockpitStopColumns,
} from "../../../shared/types/internal";
import { ReduxState } from "../../../shared/types/redux";
import {
    resetStopColumns,
    setStopColumns,
} from "../../../store/slices/cockpit";
import "./style.scss";

type Props = {};

function CockpitStopColumnsModal(props: Props) {
    const { t } = useTranslation();
    const stopColumnsSettings = useSelector(
        (state: ReduxState) => state.cockpit.stopColumns
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const sortedCockpitStopColumns = useMemo(() => {
        const cloned = [...cockpitStopColumns];
        return cloned.sort((a, b) => {
            const { position: aPosition } = stopColumnsSettings[a];
            const { position: bPosition } = stopColumnsSettings[b];

            return aPosition - bPosition;
        });
    }, [stopColumnsSettings]);

    const changeCockpitColumnHandler = useCallback(
        (column: CockpitStopColumn, show: boolean) => {
            dispatch(
                setStopColumns({
                    ...stopColumnsSettings,
                    [column]: {
                        ...stopColumnsSettings[column],
                        show,
                    },
                })
            );
        },
        [dispatch, stopColumnsSettings]
    );

    const columnLabelMap = useMemo<Record<CockpitStopColumn, string>>(() => {
        return {
            id: "",
            from: t("bigVolume.loading"),
            to: t("bigVolume.unloading"),
            contact: t("bigVolume.contact"),
            weight: t("bigVolume.weight"),
            "dropoff-time": t("bigVolume.dropoffTime"),
            tags: t("bigVolume.tags"),
            ordernumber: t("bigVolume.orderNumber"),
            "cargo-content": t("bigVolume.cargoContent"),
            "driver-instructions": t("bigVolume.driverInstructions"),
        };
    }, [t]);

    return (
        <Modal
            buttonElement={(ref) => (
                <Button
                    ref={ref}
                    variant={"secondary"}
                    label={t("bigVolume.columns")}
                    leadingIcon={faCog}
                    onClick={() => setIsModalOpen(true)}
                />
            )}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            align={"right"}
            width="200px"
        >
            <div className="cockpit-stop-columns-modal">
                <div className="header">
                    <p className="text-sm">{t("bigVolume.columns")}</p>
                    <TextButton
                        variant={"primary"}
                        label={t("bigVolume.resetColumns")}
                        onClick={() => dispatch(resetStopColumns())}
                        style={{ marginLeft: "auto" }}
                    />
                </div>

                {sortedCockpitStopColumns.map((column) => {
                    if (column === "id") return null;
                    return (
                        <Checkbox
                            key={column}
                            checked={!!stopColumnsSettings[column].show}
                            onChange={(value) =>
                                changeCockpitColumnHandler(column, value)
                            }
                            label={columnLabelMap[column]}
                        />
                    );
                })}
            </div>
        </Modal>
    );
}

export default CockpitStopColumnsModal;
