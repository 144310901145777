import { useCallback, useState } from "react";
import { StopTableData, TourTableData } from "../../pages/BigVolume";
import { CockpitStopColumn, StopFilter } from "../../shared/types/internal";
import { getStopLocationText } from "../../shared/utility/stop-draft";
import {
    getNextStop,
    getTourEndDate,
    getTourProgress,
} from "../../shared/utility/tour";
import useStopFilter from "./useStopFilter";

export type TourSort =
    | "orders-desc"
    | "orders-asc"
    | "driver-desc"
    | "driver-asc"
    | "progress-desc"
    | "progress-asc"
    | "endtime-desc"
    | "endtime-asc"
    | "nextstop-desc"
    | "nextstop-asc"
    | null;

export type StopSort =
    | `${CockpitStopColumn}-desc`
    | `${CockpitStopColumn}-asc`
    | null;

function useStopTableAndTourTableFilterAndSort() {
    //Sorting
    const [activeTourSort, setActiveTourSort] = useState<TourSort>(null);
    const [activeStopSort, setActiveStopSort] = useState<StopSort>(null);

    //filter
    const [tourSearch, setTourSearch] = useState("");

    const [stopSearch, setStopSearch] = useState("");
    const [activeStopFilter, setActiveStopFilter] = useState<StopFilter>({
        tags: [],
        pickupCities: [],
        dropoffCities: [],
    });

    const { filterStops: filterStopTable } = useStopFilter({
        activeFilter: activeStopFilter,
        stopSearch,
    });

    const filterTourTable = useCallback(
        ({ tour, stops }: TourTableData) => {
            let showBasedOnSearch = true;
            if (tourSearch) {
                const orderNumbers = new Set(
                    tour?.stops.map((stop) => stop.order_number) || [
                        stops?.pickup.order_number,
                    ]
                );

                const driverString =
                    tour?.preferred_driver ||
                    stops?.pickup.order?.carrier_delivery_requests?.[0]
                        .external_carrier?.name ||
                    stops?.pickup.location?.name ||
                    "";
                const tourMatchString = `${driverString} ${[
                    ...orderNumbers,
                ].join(" ")}`;

                showBasedOnSearch = tourMatchString
                    .toLowerCase()
                    .includes(tourSearch.toLowerCase());
            }

            return showBasedOnSearch;
        },
        [tourSearch]
    );

    const sortTourTable = useCallback(
        (a: TourTableData, b: TourTableData) => {
            if (
                activeTourSort === "orders-desc" ||
                activeTourSort === "orders-asc"
            ) {
                let aLength = 0;
                let bLength = 0;

                if (a.tour?.stops) {
                    aLength = a.tour.stops.length;
                }
                if (b.tour?.stops) {
                    bLength = b.tour.stops.length;
                }

                if (a.stops) {
                    aLength = 1;
                }
                if (b.stops) {
                    bLength = 1;
                }

                if (activeTourSort === "orders-desc") {
                    return bLength - aLength;
                }

                if (activeTourSort === "orders-asc") {
                    return aLength - bLength;
                }
            }

            if (
                activeTourSort === "driver-desc" ||
                activeTourSort === "driver-asc"
            ) {
                let aValue = "";
                let bValue = "";

                if (a.tour) {
                    aValue = a.tour.preferred_driver || "";
                }
                if (b.tour) {
                    bValue = b.tour.preferred_driver || "";
                }

                if (a.stops) {
                    aValue = a.stops.pickup.origin_location?.name || "";
                }
                if (b.stops) {
                    bValue = b.stops.pickup.origin_location?.name || "";
                }

                if (activeTourSort === "driver-desc") {
                    return bValue.localeCompare(aValue);
                }

                if (activeTourSort === "driver-asc") {
                    return aValue.localeCompare(bValue);
                }
            }

            if (
                activeTourSort === "progress-desc" ||
                activeTourSort === "progress-asc"
            ) {
                let aValue = 0;
                let bValue = 0;

                if (a.tour) {
                    aValue = getTourProgress(a.tour).progress;
                }
                if (b.tour) {
                    bValue = getTourProgress(b.tour).progress;
                }

                if (activeTourSort === "progress-desc") {
                    return bValue - aValue;
                }

                if (activeTourSort === "progress-asc") {
                    return aValue - bValue;
                }
            }

            if (
                activeTourSort === "endtime-desc" ||
                activeTourSort === "endtime-asc"
            ) {
                let aValue = null;
                let bValue = null;

                if (a.tour) {
                    aValue = getTourEndDate(a.tour.stops);
                }
                if (b.tour) {
                    bValue = getTourEndDate(b.tour.stops);
                }

                if (activeTourSort === "endtime-desc") {
                    return (bValue?.getTime() || 0) - (aValue?.getTime() || 0);
                }

                if (activeTourSort === "endtime-asc") {
                    return (aValue?.getTime() || 0) - (bValue?.getTime() || 0);
                }
            }

            if (
                activeTourSort === "nextstop-desc" ||
                activeTourSort === "nextstop-asc"
            ) {
                let aValue = null;
                let bValue = null;

                if (a.tour) {
                    aValue = getNextStop(a.tour);
                }
                if (b.tour) {
                    bValue = getNextStop(b.tour);
                }

                if (activeTourSort === "nextstop-desc") {
                    return (
                        bValue ? getStopLocationText(bValue) : ""
                    ).localeCompare(aValue ? getStopLocationText(aValue) : "");
                }

                if (activeTourSort === "nextstop-asc") {
                    return (
                        aValue ? getStopLocationText(aValue) : ""
                    ).localeCompare(bValue ? getStopLocationText(bValue) : "");
                }
            }

            return 0;
        },
        [activeTourSort]
    );

    const sortStopTable = useCallback(
        (a: StopTableData, b: StopTableData) => {
            if (
                activeStopSort === "weight-desc" ||
                activeStopSort === "weight-asc"
            ) {
                let aValue = 0;
                let bValue = 0;

                if (a.stops) {
                    aValue = a.stops.pickup.weight_kg || 0;
                }
                if (b.stops) {
                    bValue = b.stops.pickup.weight_kg || 0;
                }
                if (a.orderDraft) {
                    aValue = a.orderDraft.weight_kg || 0;
                }
                if (b.orderDraft) {
                    bValue = b.orderDraft.weight_kg || 0;
                }

                if (activeStopSort === "weight-desc") {
                    return aValue - bValue;
                }
                if (activeStopSort === "weight-asc") {
                    return bValue - aValue;
                }
            }

            if (
                activeStopSort === "from-desc" ||
                activeStopSort === "from-asc"
            ) {
                let aValue = "";
                let bValue = "";

                if (a.stops) {
                    aValue = getStopLocationText(a.stops.pickup);
                }
                if (b.stops) {
                    bValue = getStopLocationText(b.stops.pickup);
                }
                if (a.orderDraft) {
                    aValue = a.orderDraft.from_location || "";
                }
                if (b.orderDraft) {
                    bValue = b.orderDraft.from_location || "";
                }

                if (activeStopSort === "from-desc") {
                    return aValue.localeCompare(bValue);
                }
                if (activeStopSort === "from-asc") {
                    return bValue.localeCompare(aValue);
                }
            }

            if (activeStopSort === "to-desc" || activeStopSort === "to-asc") {
                let aValue = "";
                let bValue = "";

                if (a.stops) {
                    aValue = getStopLocationText(a.stops.dropoff);
                }
                if (b.stops) {
                    bValue = getStopLocationText(b.stops.dropoff);
                }
                if (a.orderDraft) {
                    aValue = a.orderDraft.to_location || "";
                }
                if (b.orderDraft) {
                    bValue = b.orderDraft.to_location || "";
                }

                if (activeStopSort === "to-desc") {
                    return aValue.localeCompare(bValue);
                }
                if (activeStopSort === "to-asc") {
                    return bValue.localeCompare(aValue);
                }
            }

            if (
                activeStopSort === "ordernumber-desc" ||
                activeStopSort === "ordernumber-asc"
            ) {
                let aValue = "";
                let bValue = "";

                if (a.stops) {
                    aValue = a.stops.pickup.order_number;
                }
                if (b.stops) {
                    bValue = b.stops.pickup.order_number;
                }
                if (a.orderDraft) {
                    aValue = a.orderDraft.origin_order_number || "";
                }
                if (b.orderDraft) {
                    bValue = b.orderDraft.origin_order_number || "";
                }

                if (activeStopSort === "ordernumber-desc") {
                    return aValue.localeCompare(bValue);
                }
                if (activeStopSort === "ordernumber-asc") {
                    return bValue.localeCompare(aValue);
                }
            }

            if (
                activeStopSort === "contact-desc" ||
                activeStopSort === "contact-asc"
            ) {
                let aValue = "";
                let bValue = "";

                if (a.stops) {
                    aValue =
                        a.stops.dropoff.contact?.name ||
                        a.stops.pickup.contact?.name ||
                        "";
                }
                if (b.stops) {
                    bValue =
                        b.stops.dropoff.contact?.name ||
                        b.stops.pickup.contact?.name ||
                        "";
                }
                if (a.orderDraft) {
                    aValue =
                        a.orderDraft.dropoff_contact?.name ||
                        a.orderDraft.pickup_contact?.name ||
                        "";
                }
                if (b.orderDraft) {
                    bValue =
                        b.orderDraft.dropoff_contact?.name ||
                        b.orderDraft.pickup_contact?.name ||
                        "";
                }

                if (activeStopSort === "contact-desc") {
                    return aValue.localeCompare(bValue);
                }
                if (activeStopSort === "contact-asc") {
                    return bValue.localeCompare(aValue);
                }
            }

            if (
                activeStopSort === "tags-desc" ||
                activeStopSort === "tags-asc"
            ) {
                let aValue = "";
                let bValue = "";

                if (a.stops) {
                    aValue = a.stops.pickup.tags?.join(" ") || "";
                }
                if (b.stops) {
                    bValue = b.stops.pickup.tags?.join(" ") || "";
                }
                if (a.orderDraft) {
                    aValue = a.orderDraft.tags?.join(" ") || "";
                }
                if (b.orderDraft) {
                    bValue = b.orderDraft.tags?.join(" ") || "";
                }

                if (activeStopSort === "tags-desc") {
                    return aValue.localeCompare(bValue);
                }
                if (activeStopSort === "tags-asc") {
                    return bValue.localeCompare(aValue);
                }
            }

            if (
                activeStopSort === "dropoff-time-desc" ||
                activeStopSort === "dropoff-time-asc"
            ) {
                const aValue = a.stops?.dropoff.time_tooltip || "";
                const bValue = b.stops?.dropoff.time_tooltip || "";

                if (activeStopSort === "dropoff-time-desc") {
                    return bValue.localeCompare(aValue);
                }
                if (activeStopSort === "dropoff-time-asc") {
                    return aValue.localeCompare(bValue);
                }
            }

            if (
                activeStopSort === "cargo-content-desc" ||
                activeStopSort === "cargo-content-asc"
            ) {
                let aValue =
                    a.stops?.pickup.cargo_content ||
                    a.orderDraft?.cargo_content ||
                    "";
                let bValue =
                    b.stops?.pickup.cargo_content ||
                    b.orderDraft?.cargo_content ||
                    "";

                if (activeStopSort === "cargo-content-desc") {
                    return bValue.localeCompare(aValue);
                }
                if (activeStopSort === "cargo-content-asc") {
                    return aValue.localeCompare(bValue);
                }
            }

            if (
                activeStopSort === "driver-instructions-desc" ||
                activeStopSort === "driver-instructions-asc"
            ) {
                let aValue =
                    a.stops?.pickup.driver_instructions ||
                    a.orderDraft?.driver_instructions ||
                    "";
                let bValue =
                    b.stops?.pickup.driver_instructions ||
                    b.orderDraft?.driver_instructions ||
                    "";

                if (activeStopSort === "driver-instructions-desc") {
                    return bValue.localeCompare(aValue);
                }
                if (activeStopSort === "driver-instructions-asc") {
                    return aValue.localeCompare(bValue);
                }
            }

            return 0;
        },
        [activeStopSort]
    );

    return {
        sortStopTable,
        sortTourTable,

        filterStopTable,
        filterTourTable,

        activeStopFilter,
        setActiveStopFilter,
        activeStopSort,
        setActiveStopSort,
        stopSearch,
        setStopSearch,

        activeTourSort,
        setActiveTourSort,
        tourSearch,
        setTourSearch,
    };
}

export default useStopTableAndTourTableFilterAndSort;
