import { faExpand } from "@fortawesome/pro-solid-svg-icons";
import { useMap } from "@vis.gl/react-google-maps";
import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BigVolumeMapModal from "../../big-volume/modals/BigVolumeMapModal";
import Button from "../../components/buttons/Button";
import useDefaultMapCenter from "../../hooks/functionality/useDefaultMapCenter";
import { Driver, StopDraft, StopDraftsTour } from "../../shared/types/api";
import { DriverStatus, KeyString } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { isDriverTimestampTooOld } from "../../shared/utility/date";
import { getBoundForLocations } from "../../shared/utility/misc";
import { getTourStatus } from "../../shared/utility/tour";
import {
    NEW_TOUR_COLUMN_ID,
    UNHANDLED_STOPS_ID,
} from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import Map from "../Map";
import DriverMarkerNew from "../markers/DriverMarkerNew";
import StopMarkers from "../markers/StopMarkers";
import TourMarkers from "../markers/TourMarkers";
import "./style.scss";

type Props = {
    tours: StopDraftsTour[];
    stops?: StopDraft[];
    unhandledStops?: StopDraft[];
    drivers?: Driver[];
    driverStatusMap: KeyString<DriverStatus>;
    showDrivers?: boolean;
    hoveredStopId?: number | null;
    tourColorMap?: KeyString<{
        primary: string;
        accent: string;
    }>;
    onSelectStops?: (stops: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onSelectMultipleStops?: (
        stops: { pickup: StopDraft; dropoff: StopDraft }[]
    ) => void;
};

function BigVolumeMap(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const defaultCenter = useDefaultMapCenter();
    const map = useMap();

    const [showUnhandledStops, setShowUnhandledStops] = useState(true);
    const [showDrivers, setShowDrivers] = useState(false);
    const [focusedStop, setFocusedStop] = useState<StopDraft | null>(null);

    useEffect(() => {
        if (!map) return;
        if (!user?.location_entity?.lat || !user?.location_entity?.lng) return;

        map.panTo({
            lat: user.location_entity.lat,
            lng: user.location_entity.lng,
        });
    }, [map, user?.location_entity?.lat, user?.location_entity?.lng]);

    useEffect(() => {
        if (!props.unhandledStops) return;
        if (
            !props.unhandledStops.some(
                (s) => s.id === focusedStop?.id || s.id === focusedStop?.id
            )
        ) {
            setFocusedStop(null);
        }
    }, [props.unhandledStops, focusedStop?.id]);

    useEffect(() => {
        if (props.showDrivers) {
            setShowDrivers(props.showDrivers);
        }
    }, [props.showDrivers]);

    const focusStopHandler = useCallback(
        (stop: StopDraft) => setFocusedStop(stop),
        []
    );

    const fitBoundsToStops = useCallback(
        (stops: StopDraft[]) => {
            if (!map) return;

            const bounds = getBoundForLocations(
                stops.map((stop) => ({
                    lat: +stop.lat!,
                    lng: +stop.lng!,
                }))
            );

            if (bounds.isEmpty()) return;

            map.fitBounds(bounds, 100);
        },
        [map]
    );

    useEffect(() => {
        const stopsToFit: StopDraft[] = [];
        if (props.stops) {
            stopsToFit.push(...props.stops);
        }

        if (props.tours) {
            stopsToFit.push(...props.tours.flatMap((tour) => tour.stops));
        }

        fitBoundsToStops(stopsToFit);
    }, [fitBoundsToStops, props.stops, props.tours]);

    const driverColorMap = useMemo(() => {
        const newMap: KeyString<{
            primary: string;
            accent: string;
        }> = {};

        if (!props.drivers || !props.tourColorMap) return newMap;

        for (let i = 0; i < props.drivers.length; i++) {
            const driver = props.drivers[i];

            if (!driver.mt_driver_id) continue;

            const toursForDriver = props.tours.filter(
                (t) => t.preferred_driver_id === driver.mt_driver_id
            );
            if (!toursForDriver.length) continue;

            let colors = props.tourColorMap[toursForDriver[0].tour_id];

            // If there is more than one tour for the driver, take the first active tour
            if (toursForDriver.length > 1) {
                const firstActiveTour = toursForDriver.find(
                    (t) => getTourStatus(t) === "active"
                );

                if (firstActiveTour) {
                    colors = props.tourColorMap[firstActiveTour.tour_id];
                }
            }

            newMap[driver.mt_driver_id] = colors;
        }

        return newMap;
    }, [props.drivers, props.tourColorMap, props.tours]);

    return (
        <div className="big-volume-map">
            <div className="controls">
                {props.stops && props.stops.length > 0 && (
                    <Button
                        key={NEW_TOUR_COLUMN_ID}
                        variant={"secondary"}
                        label={t("bigVolume.plannedStops")}
                        leadingIcon={faExpand}
                        onClick={() => fitBoundsToStops(props.stops!)}
                        leadingIconColor={
                            props.tourColorMap?.[NEW_TOUR_COLUMN_ID].primary
                        }
                    />
                )}

                {props.tours.map((tour) => (
                    <Button
                        key={tour.tour_id}
                        variant={"secondary"}
                        label={
                            tour.preferred_driver ||
                            t("bigVolume.unassignedTourLabel")
                        }
                        leadingIcon={faExpand}
                        onClick={() => fitBoundsToStops(tour.stops)}
                        leadingIconColor={
                            props.tourColorMap?.[tour.tour_id].primary
                        }
                    />
                ))}
            </div>
            <Map
                mapId={GOOGLE_MAP_IDS.ToursMap}
                defaultCenter={defaultCenter}
                defaultZoom={12}
                onClick={() => {
                    setFocusedStop(null);
                }}
                showTrafficToggle
                controls={
                    <BigVolumeMapModal
                        states={{
                            showUnhandledStops,
                            showDrivers,
                        }}
                        onChange={(key, value) => {
                            switch (key) {
                                case "showUnhandledStops":
                                    setShowUnhandledStops(value);
                                    break;
                                case "showDrivers":
                                    setShowDrivers(value);
                                    break;
                            }
                        }}
                    />
                }
            >
                <AnimatePresence>
                    {props.tours.map((tour, i) => (
                        <TourMarkers
                            hoveredStopId={props.hoveredStopId}
                            key={tour.tour_id}
                            tour={tour}
                            tourColor={props.tourColorMap?.[tour.tour_id]}
                        />
                    ))}
                </AnimatePresence>

                <AnimatePresence>
                    {props.stops && (
                        <TourMarkers
                            hoveredStopId={props.hoveredStopId}
                            key={NEW_TOUR_COLUMN_ID}
                            stops={props.stops}
                            tourColor={props.tourColorMap?.[NEW_TOUR_COLUMN_ID]}
                        />
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {props.unhandledStops && showUnhandledStops && (
                        <StopMarkers
                            key={UNHANDLED_STOPS_ID}
                            stops={props.unhandledStops.filter(
                                (stop) =>
                                    !props.stops?.some((s) => s.id === stop.id)
                            )}
                            onClick={focusStopHandler}
                            focusedStop={focusedStop || undefined}
                            onSelectStops={(stops) => {
                                props.onSelectStops?.(stops);
                                setFocusedStop(null);
                            }}
                            onSelectMultipleStops={(stops) => {
                                props.onSelectMultipleStops?.(stops);
                                setFocusedStop(null);
                            }}
                        />
                    )}
                </AnimatePresence>

                {props.drivers &&
                    showDrivers &&
                    props.drivers.map((driver, i) => (
                        <DriverMarkerNew
                            key={driver.id}
                            driver={driver}
                            driverStatusMap={props.driverStatusMap}
                            driverColorMap={driverColorMap}
                            zIndex={
                                driverColorMap[driver.mt_driver_id || ""]
                                    ? 100000
                                    : i
                            }
                            hide={
                                driver.position_updated_at
                                    ? isDriverTimestampTooOld(
                                          driver.position_updated_at
                                      )
                                    : true
                            }
                        />
                    ))}
            </Map>
        </div>
    );
}

export default BigVolumeMap;
