import { DraggableProvided } from "@hello-pangea/dnd";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DotsModal, { DotsModalContent } from "../../../components/UI/DotsModal";
import IconOLD from "../../../components/UI/IconOLD";
import StatusCircle from "../../../components/UI/StatusCircle";
import DotsButton from "../../../components/buttons/DotsButton";
import TextButton from "../../../components/buttons/TextButton";
import ColorPicker from "../../../components/inputs/ColorPicker";
import RadioInput from "../../../components/inputs/RadioInput";
import useHasActiveEllipsis from "../../../hooks/functionality/useHasActiveEllipsis";
import { StopDraft } from "../../../shared/types/api";
import { ReduxState } from "../../../shared/types/redux";
import { formatDateString, formatEta } from "../../../shared/utility/date";
import { getOrderStatus } from "../../../shared/utility/order";
import { getStopDraftStatus } from "../../../shared/utility/stop-draft";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import Badge from "../../../components/UI/Badge";
import { faTag, faTags } from "@fortawesome/pro-regular-svg-icons";
import "./style.scss";

export type StopDraftCardProps = {
    // Core Data
    stopDraft: StopDraft;
    index?: number;
    runningWeight?: number;

    // Drag and Drop
    provided?: DraggableProvided;

    // Action Handlers
    onClick?: () => void;
    modalContent?: DotsModalContent[];
    onHover?: (groupId: number) => void;
    onColorChange?: (stopDraft: StopDraft, color: string | null) => void;
    isChangingColor?: boolean;
    onCheck?: (stopDraft: StopDraft) => void;
    checked?: boolean;
    onDegroup?: (stopDraft: StopDraft) => void;
    isDegrouping?: boolean;

    // Display Options
    isInWrongDate?: boolean;
    showPosition?: boolean;
    hideEta?: boolean;
    isUndispatched?: boolean;
    hasGroupsAround?: {
        above: boolean;
        below: boolean;
    };
};

function StopDraftCard(props: StopDraftCardProps) {
    const { t } = useTranslation();
    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);

    const cargoContentText = useRef<HTMLParagraphElement | null>(null);
    const { hasActiveEllipsis } = useHasActiveEllipsis(
        cargoContentText,
        props.stopDraft.cargo_content
    );

    const [isCargoContentExpanded, setIsCargoContentExpanded] = useState(false);

    const stopDraftCardClasses = ["stop-draft-card"];

    if (props.hasGroupsAround?.above) {
        stopDraftCardClasses.push("grouped-above");
    }

    if (props.hasGroupsAround?.below) {
        stopDraftCardClasses.push("grouped-below");
    }

    const orderStatus = props.stopDraft.order
        ? getOrderStatus(props.stopDraft.order)
        : undefined;

    const { showStatus, isStopCompleted, statusText } = useMemo(
        () =>
            getStopDraftStatus({
                stopDraft: props.stopDraft,
                orderStatus,
            }),
        [orderStatus, props.stopDraft]
    );

    const border = useMemo(
        () =>
            props.isUndispatched
                ? "1px solid var(--error-color)"
                : orderStatus && showStatus
                ? `1px solid var(--order-${orderStatus}-color)`
                : undefined,
        [orderStatus, props.isUndispatched, showStatus]
    );

    const etaString = useMemo(() => {
        if (
            props.stopDraft.completed_at ||
            props.stopDraft.arrived_at ||
            props.stopDraft.eta_internal
        ) {
            return formatEta(
                props.stopDraft.completed_at ||
                    props.stopDraft.arrived_at ||
                    props.stopDraft.eta_internal ||
                    undefined
            );
        }
    }, [
        props.stopDraft.arrived_at,
        props.stopDraft.completed_at,
        props.stopDraft.eta_internal,
    ]);

    const shouldShowEta = useMemo(() => {
        return !isStopCompleted && !props.hideEta && etaString;
    }, [etaString, isStopCompleted, props.hideEta]);

    return (
        <div
            className={stopDraftCardClasses.join(" ")}
            onMouseEnter={() => props.onHover?.(props.stopDraft.group_id)}
            onMouseLeave={() => props.onHover?.(-1)}
            data-group-id={props.stopDraft.group_id}
            data-card-id={props.stopDraft.id}
            onClick={props.onClick}
            ref={props.provided?.innerRef}
            {...props.provided?.draggableProps}
            style={{
                ...props.provided?.draggableProps.style,
                border,
            }}
            {...props.provided?.dragHandleProps}
        >
            <div className="top">
                <div className="order-number">
                    <p className="text-xs" title={props.stopDraft.order_number}>
                        {props.stopDraft.order_number}
                    </p>
                    {props.onDegroup && (
                        <TextButton
                            onClick={() => props.onDegroup?.(props.stopDraft)}
                            label={t("fleetPlanner.degroup")}
                            isLoading={props.isDegrouping}
                            variant="link"
                            style={{
                                minWidth: "150px",
                            }}
                        />
                    )}
                    {props.modalContent && (
                        <DotsModal
                            content={props.modalContent}
                            dotsStyle={{ backgroundColor: "transparent" }}
                            dotsColor="var(--text-color-light)"
                        />
                    )}
                </div>
                {props.isInWrongDate && props.stopDraft.date_tooltip && (
                    <p
                        className="text-xs"
                        style={{
                            color: props.isInWrongDate
                                ? "var(--error-color)"
                                : "var(--text-color-light)",
                        }}
                    >
                        {props.stopDraft.date_tooltip}
                    </p>
                )}
            </div>

            {(props.stopDraft.order?.assigned_location_id ||
                props.stopDraft.origin_location) && (
                <div className="assigned-wrapper">
                    {props.stopDraft.order?.assigned_location_id && (
                        <p
                            className="text-xs"
                            style={{
                                color: "var(--text-color-alt)",
                            }}
                        >
                            {t("fleetPlanner.assignedByAlrik")}
                        </p>
                    )}

                    {props.stopDraft.origin_location && (
                        <p className="text-xs">
                            {t("fleetPlanner.assignedByOtherLocation")}{" "}
                            {props.stopDraft.origin_location.name}
                        </p>
                    )}
                </div>
            )}

            <div className="addresses-wrapper">
                <section>
                    <IconOLD
                        type={
                            props.stopDraft.stop_type_id ===
                            STOP_DRAFT_TYPE.Pickup
                                ? "plane-departure"
                                : "race-flag"
                        }
                        style={{
                            marginTop: "4px",
                            minWidth: "20px",
                        }}
                    />
                    <div className="address">
                        <div className="address-text">
                            {props.onColorChange ? (
                                <ColorPicker
                                    value={props.stopDraft.color || null}
                                    onChange={(color) =>
                                        props.onColorChange?.(
                                            props.stopDraft,
                                            color
                                        )
                                    }
                                    isLoading={props.isChangingColor}
                                />
                            ) : (
                                props.stopDraft.color && (
                                    <DotsButton
                                        amount={1}
                                        color={
                                            props.stopDraft.color || undefined
                                        }
                                        style={{
                                            backgroundColor: "transparent",
                                            padding: "0",
                                        }}
                                        large
                                    />
                                )
                            )}
                            <span className="text-sm">
                                {props.showPosition &&
                                    props.index !== undefined &&
                                    `${props.index + 1}. `}
                                {props.stopDraft.to_location}
                            </span>
                        </div>

                        <div className="address-info">
                            {props.onCheck && (
                                <RadioInput
                                    checked={props.checked || false}
                                    onClick={() =>
                                        props.onCheck?.(props.stopDraft)
                                    }
                                    style={{ marginRight: "8px" }}
                                />
                            )}
                            {props.stopDraft.contact && (
                                <span className="text-xs">
                                    {props.stopDraft.contact.name}
                                </span>
                            )}
                            {props.stopDraft.time_tooltip &&
                                props.stopDraft.contact && (
                                    <span className="text-xs">-</span>
                                )}
                            {props.stopDraft.time_tooltip && (
                                <span className="text-xs">
                                    {props.stopDraft.time_tooltip}
                                </span>
                            )}
                        </div>
                        {props.stopDraft.driver_note && (
                            <p className="driver-note">
                                <IconOLD
                                    type="comment"
                                    style={{ minWidth: "20px" }}
                                />
                                <span className="text-xs">
                                    {props.stopDraft.driver_note}
                                </span>
                            </p>
                        )}
                    </div>
                </section>
            </div>

            {props.stopDraft.cargo_content &&
                !!settings.showCargoContent &&
                props.stopDraft.stop_type_id === STOP_DRAFT_TYPE.Dropoff && (
                    <div className="cargo-content-wrapper">
                        <p
                            ref={cargoContentText}
                            className={`cargo-content${
                                isCargoContentExpanded ? " expanded" : ""
                            }`}
                            style={{ color: "var(--text-color-light)" }}
                        >
                            {props.stopDraft.cargo_content}
                        </p>

                        {hasActiveEllipsis && (
                            <TextButton
                                label={
                                    isCargoContentExpanded
                                        ? t("activeFleet.showLess")
                                        : t("activeFleet.showMore")
                                }
                                variant="link"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsCargoContentExpanded(
                                        !isCargoContentExpanded
                                    );
                                }}
                                style={{
                                    width: "fit-content",
                                }}
                            />
                        )}
                    </div>
                )}

            {(showStatus || isStopCompleted || shouldShowEta) && (
                <div className="status-wrapper">
                    {(showStatus || isStopCompleted) && orderStatus ? (
                        <>
                            <StatusCircle
                                status={
                                    isStopCompleted ? "fulfilled" : orderStatus
                                }
                            />
                            <span className="text-xs">
                                {t(
                                    isStopCompleted &&
                                        props.stopDraft.stop_type_id ===
                                            STOP_DRAFT_TYPE.Pickup
                                        ? "orderHistory.statusPickedUp"
                                        : statusText
                                )}
                            </span>
                        </>
                    ) : null}
                    {isStopCompleted && (
                        <span
                            style={{
                                color: "var(--text-color-light)",
                                marginLeft: "auto",
                            }}
                        >
                            {props.stopDraft.stop_type_id ===
                            STOP_DRAFT_TYPE.Pickup
                                ? props.stopDraft.order?.driver_picked_up_at &&
                                  formatDateString(
                                      props.stopDraft.order.driver_picked_up_at,
                                      {
                                          onlyTime: true,
                                      }
                                  )
                                : props.stopDraft.order?.fulfilled_at &&
                                  formatDateString(
                                      props.stopDraft.order.fulfilled_at,
                                      {
                                          onlyTime: true,
                                      }
                                  )}
                        </span>
                    )}
                    {!isStopCompleted && shouldShowEta && (
                        <span
                            style={{
                                color: "var(--text-color-light)",
                                marginLeft: "auto",
                            }}
                        >
                            {`ETA: ${etaString}`}
                        </span>
                    )}
                </div>
            )}

            <div className="bottom-info">
                {props.stopDraft.weight_kg ||
                props.runningWeight !== undefined ? (
                    <p
                        style={{
                            color: "var(--text-color-light)",
                        }}
                    >
                        <IconOLD
                            type="weight"
                            style={{
                                minWidth: "20px",
                            }}
                        />
                        {`${
                            props.stopDraft.weight_kg
                                ? props.stopDraft.stop_type_id ===
                                  STOP_DRAFT_TYPE.Dropoff
                                    ? "-"
                                    : "+"
                                : ""
                        }${props.stopDraft.weight_kg || 0} kg ( ${
                            props.runningWeight || 0
                        } kg )`}
                    </p>
                ) : (
                    <div />
                )}
                {props.stopDraft.tags ? (
                    props.stopDraft.tags.length > 1 ? (
                        <Badge
                            title={`+${props.stopDraft.tags.length} ${t(
                                "bigVolume.cardTags"
                            )}`}
                            variant="neutral"
                            icon={faTags}
                            htmlTitle={props.stopDraft.tags.join(", ")}
                        />
                    ) : props.stopDraft.tags.length === 1 ? (
                        <Badge
                            title={props.stopDraft.tags[0]}
                            variant="neutral"
                            icon={faTag}
                        />
                    ) : null
                ) : null}
            </div>
        </div>
    );
}

export default StopDraftCard;
