import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useMemo, useState } from "react";
import Spinner from "../../UI/Spinner";
import Tooltip from "../../UI/Tooltip";
import "./style.scss";

type TextButtonColor = {
    color: string;
    iconColor: string;
};

type TextButtonVariants = {
    primary: TextButtonColor;
    secondary: TextButtonColor;
    link: TextButtonColor;
    negative: TextButtonColor;
    disabled: TextButtonColor;
};

const variants: TextButtonVariants = {
    primary: {
        color: "var(--color-primary-600)",
        iconColor: "var(--color-primary-500)",
    },
    secondary: {
        color: "var(--color-neutral-900)",
        iconColor: "var(--color-neutral-600)",
    },
    link: {
        color: "var(--color-blue-500)",
        iconColor: "var(--color-blue-500)",
    },
    negative: {
        color: "var(--color-red-700)",
        iconColor: "var(--color-red-500)",
    },
    disabled: {
        color: "var(--color-neutral-400)",
        iconColor: "var(--color-neutral-400)",
    },
};

type Props = {
    label?: string;
    variant: keyof TextButtonVariants;
    leadingIcon?: IconDefinition;
    leadingIconColor?: string;
    trailingIcon?: IconDefinition;
    onClick?: (event: React.MouseEvent) => void;
    style?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    className?: string;
    isLoading?: boolean;
    disabled?: boolean;
    noSubmit?: boolean;
    tooltipData?: {
        description: string;
        width?: string;
    };
};

const TextButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const buttonClasses = ["text-button"];

    if (props.className) {
        buttonClasses.push(props.className);
    }

    const { color, iconColor } = useMemo(() => {
        return props.disabled ? variants.disabled : variants[props.variant];
    }, [props.disabled, props.variant]);

    return (
        <div
            className="text-button-wrapper"
            onMouseEnter={
                props.tooltipData ? () => setShowTooltip(true) : undefined
            }
            onMouseLeave={
                props.tooltipData ? () => setShowTooltip(false) : undefined
            }
        >
            {props.tooltipData && showTooltip && (
                <Tooltip
                    style={{
                        bottom: "calc(100% + 6px)",
                        width: props.tooltipData.width,
                    }}
                >
                    <p>{props.tooltipData.description}</p>
                </Tooltip>
            )}
            <button
                ref={ref}
                onClick={(e) => {
                    if (props.isLoading || props.disabled) {
                        e.preventDefault();
                        return;
                    }
                    if (props.onClick) props.onClick(e);
                }}
                className={buttonClasses.join(" ")}
                disabled={props.disabled}
                style={{ ...props.style }}
                type={props.noSubmit || props.onClick ? "button" : undefined}
            >
                {/* Leading icon */}
                {props.isLoading && (
                    <div className="button-loading">
                        <Spinner width="15px" />
                    </div>
                )}
                {props.leadingIcon && !props.isLoading && (
                    <FontAwesomeIcon
                        icon={props.leadingIcon}
                        size="lg"
                        color={props.leadingIconColor || iconColor}
                    />
                )}

                {/* Label */}
                {props.label && (
                    <p
                        className="text-xs"
                        style={{
                            color,
                            ...props.labelStyle,
                        }}
                    >
                        {props.label}
                    </p>
                )}

                {/* Trailing icon */}
                {props.trailingIcon &&
                    (props.isLoading ? (
                        <div className="button-loading">
                            <Spinner width="15px" />
                        </div>
                    ) : (
                        <FontAwesomeIcon
                            icon={props.trailingIcon}
                            size="lg"
                            color={iconColor}
                        />
                    ))}
            </button>
        </div>
    );
});

export default TextButton;
