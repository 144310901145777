import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import IconOLD from "../../components/UI/IconOLD";
import Spinner from "../../components/UI/Spinner";
import Button from "../../components/buttons/Button";
import OrderDetails from "../../components/widgets/OrderDetails";
import usePublicOrder from "../../hooks/data/usePublicOrder";
import { useSearchQuery } from "../../hooks/functionality/useSearchQuery";
import PointsMap from "../../maps/PointsMap";
import { IconType, MapPoint } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { formatDateString, formatEta } from "../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../shared/utility/misc";
import { QUERY_PARAM, ROUTE } from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import "./style.scss";

type Props = {
    shouldUseQueryParams?: boolean;
};

function PublicOrder(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const navigate = useNavigate();

    const { publicId: paramPublicId } = useParams<{ publicId: string }>();
    const query = useSearchQuery();

    const [publicOrderId, setPublicOrderId] = useState("");

    useEffect(() => {
        if (props.shouldUseQueryParams) {
            const queryPublicId = query.get(QUERY_PARAM.PublicOrderId);
            if (!queryPublicId) {
                navigate("/");
                return;
            }
            setPublicOrderId(queryPublicId);
        } else {
            if (!paramPublicId) {
                navigate("/");
                return;
            }
            setPublicOrderId(paramPublicId);
        }
    }, [navigate, props.shouldUseQueryParams, paramPublicId, query]);

    const { publicOrder, status } = usePublicOrder(publicOrderId);

    const mapPoints = useMemo(() => {
        if (!publicOrder) return [];

        const points: MapPoint[] = [];

        if (publicOrder.pickup_lat && publicOrder.pickup_lng) {
            points.push({
                id: "pickup",
                location: {
                    lat: +publicOrder.pickup_lat,
                    lng: +publicOrder.pickup_lng,
                },
                infoWindow: publicOrder.pickup_address
                    ? {
                          title: publicOrder.pickup_address,
                      }
                    : undefined,
                label: "1",
            });
        }

        if (publicOrder.dropoff_lat && publicOrder.dropoff_lng) {
            points.push({
                id: "dropoff",
                location: {
                    lat: +publicOrder.dropoff_lat,
                    lng: +publicOrder.dropoff_lng,
                },
                infoWindow: publicOrder.dropoff_address
                    ? {
                          title: publicOrder.dropoff_address,
                      }
                    : undefined,
                label: "2",
            });
        }

        return points;
    }, [publicOrder]);

    return (
        <div
            className="public-order"
            style={{
                marginBottom: user ? "20px" : undefined,
            }}
        >
            {status !== "success" ? (
                <Spinner padding="10px" />
            ) : (
                <div
                    className="content"
                    style={{
                        maxHeight: !user ? "calc(100vh - 22px)" : undefined,
                    }}
                >
                    <div className="map-wrapper">
                        <PointsMap
                            mapId={GOOGLE_MAP_IDS.PublicOrderMap}
                            showDirections
                            points={mapPoints}
                        />
                    </div>

                    <div className="order-info">
                        <OrderDetails
                            order={publicOrder}
                            getSections={(order) => [
                                [
                                    {
                                        title: t("popup.orderInfo.etaLabel"),
                                        value: formatEta(
                                            order.dropoff_eta || undefined,
                                            {
                                                fullDate: true,
                                            }
                                        ),
                                        hide: !order.dropoff_eta,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.weightLabel"),
                                        value: order.weight_kg
                                            ? convertToNumberWithSpaces(
                                                  order.weight_kg,
                                                  "kg"
                                              )
                                            : "-",
                                        hide: !order.weight_kg,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.confirmOrder.numberOfPackagesLabel"
                                        ),
                                        value: order.additional_info
                                            ?.number_of_eu_pallets,
                                        hide: !order.additional_info
                                            ?.number_of_eu_pallets,
                                    },
                                    {
                                        title: t(
                                            "popup.confirmOrder.weightOfHeaviestPackageLabel"
                                        ),
                                        value: order.weight_of_heaviest_package,
                                        hide: !order.weight_of_heaviest_package,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.vehicleLabel"
                                        ),
                                        valueComponent: (
                                            <span>
                                                <IconOLD
                                                    type={
                                                        publicOrder.vehicle_type as IconType
                                                    }
                                                    style={{
                                                        color: "var(--text-color-light)",
                                                    }}
                                                />{" "}
                                                {order.vehicle?.friendlyname}
                                            </span>
                                        ),
                                        hide: !order.vehicle?.friendlyname,
                                    },
                                    {
                                        title: t(
                                            "createOrder.additionalVehicleTypeLabel"
                                        ),
                                        value: order.additional_info
                                            ?.side_loading
                                            ? t("general.yes")
                                            : t("general.no"),
                                        hide:
                                            order.vehicle?.car_name !==
                                            "large-van",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.deliveryLabel"
                                        ),
                                        value: order.delivery?.friendlyname,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.confirmOrder.earliestPickupLabel"
                                        ),
                                        value: order.earliest_pickup
                                            ? formatDateString(
                                                  order.earliest_pickup,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : "-",
                                        hide: !order.earliest_pickup,
                                    },
                                    {
                                        title: t(
                                            "popup.confirmOrder.latestDeliveryLabel"
                                        ),
                                        value: order.latest_delivery
                                            ? formatDateString(
                                                  order.latest_delivery,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : "-",
                                        hide: !order.latest_delivery,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.fromLabel"),
                                        value: order.pickup_address,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.toLabel"),
                                        value: order.dropoff_address,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.orderNumberLabel"
                                        ),
                                        value: order.origin_order_id,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.cargoContentLabel"
                                        ),
                                        value: order.cargo_content,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.contactPhoneLabel"
                                        ),
                                        value: order.dropoff_contact_phone,
                                    },
                                ],
                            ]}
                            getShowMoreSections={(order) => [
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoCompany"
                                        ),
                                        value:
                                            order.additional_info
                                                ?.consignee_company || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoUnloading"
                                        ),
                                        value:
                                            order.additional_info
                                                ?.estimated_offload_time || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoAdditionalContact"
                                        ),
                                        valueComponent:
                                            publicOrder.additional_info?.contacts?.map(
                                                (c, i) => (
                                                    <p
                                                        className="value text-sm"
                                                        key={i}
                                                    >
                                                        <span>
                                                            {c.consignee_name}
                                                        </span>
                                                        <span>
                                                            {c.consignee_phone}
                                                        </span>
                                                    </p>
                                                )
                                            ) || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoSpecificDropoff"
                                        ),
                                        value:
                                            publicOrder.additional_info
                                                ?.offload_instructions || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoTotalCargo"
                                        ),
                                        valueComponent:
                                            publicOrder.additional_info?.cargo_dimensions?.map(
                                                (cd, i) => (
                                                    <p
                                                        className="value text-sm"
                                                        key={i}
                                                    >
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownLengthLabel"
                                                            )}
                                                            :{cd.length || "-"}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownWidthLabel"
                                                            )}
                                                            :{cd.width || "-"}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownHeightLabel"
                                                            )}
                                                            :{cd.height || "-"}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownWeightLabel"
                                                            )}
                                                            : {cd.weight || "-"}
                                                        </span>
                                                    </p>
                                                )
                                            ) || "-",
                                    },
                                ],
                            ]}
                        />

                        {!user && (
                            <div className="bottom">
                                <NavLink
                                    to={ROUTE.Login}
                                    style={{ width: "100%" }}
                                >
                                    <Button
                                        label={t("register.loginLabel")}
                                        variant="secondary"
                                    />
                                </NavLink>
                                <NavLink
                                    to={{
                                        pathname: ROUTE.Register,
                                        search: "?utm_source=public-order",
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    <Button
                                        label={t("register.createAccountLabel")}
                                        variant="secondary"
                                    />
                                </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PublicOrder;
