import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import fleetPlannerReducer from "./slices/fleetPlanner";
import cockpitReducer from "./slices/cockpit";

const rootReducer = combineReducers({
    auth: authReducer,
    fleetPlanner: fleetPlannerReducer,
    cockpit: cockpitReducer,
});

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
});

export default store;
