import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/Button";
import StopDraftCard from "../../fleet-planner/cards/StopDraftCard";
import Popup from "../../hoc/Popup";
import { CalendarTour, StopDraft } from "../../shared/types/api";
import {
    cardHoverHandler,
    checkForGroupsAround,
} from "../../shared/utility/stop-draft";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import TourMap from "../TourMap";
import "./style.scss";
import { isEnvironment } from "../../shared/utility/misc";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    tour: CalendarTour;
    onStopClick: (stop: StopDraft) => void;
};

const FAKE_PACKING_INSTRUCTIONS = `Allt i Bygg
Trädgårdsgatan 2, 52230 Tidaholm
2684108 01
KV 00 2 VÄGGEN
Till höger om ingång på gräset
__________________
Lindström, Rune
Skolgatan 8, 52231 Tidaholm
2684029 01
1   KV 00 1 VÄGGEN
Fre v3 eller Mån v4, uppfarten långt in
__________________
Pihl, Torbjörn
Agnetorp Lövkullen 1, 52294 Tidaholm
2683963 01
2   KV 00 1 VÄGGEN
Under veckan om det går
__________________
Kontantfaktura Tidaholm
Krogstorpsgatan 2, 52293 Tidaholm
2684061 01
KV 00 1 VÄGGEN
Så snart det går
__________________
Kontantfaktura Tidaholm
Suntak Kvarnegården, 52293 Tidaholm
2684111 01
FA`;

function CalendarTourMap(props: Props) {
    const { t } = useTranslation();

    const printPackingInstructions = useCallback(() => {
        let printWindow = window.open("", "", "width=840,height=1200");
        if (!printWindow) return;
        printWindow.document.write(`
                <html>
                <head>
                    <title>${t("popup.stopsInfo.packingInstructions")}-${
            props.tour.id
        }   
                    </title>
                    <style>
                        body { font-family: Arial, sans-serif; font-size: 24px; }
                        h1 { margin-bottom: 10px; }
                        p { white-space: pre-wrap; }
                    </style>
                </head>
                <body>
                    <h1>${t("popup.stopsInfo.packingInstructions")}</h1>
                    <p>${FAKE_PACKING_INSTRUCTIONS}</p>
                </body>
                </html>
            `);
        printWindow.document.close();
        printWindow.print();
    }, [props.tour.id, t]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            overlayComponent={
                <TourMap
                    tour={{
                        ...props.tour,
                        tour_id: props.tour.id,
                        orders: [],
                    }}
                    mapId={GOOGLE_MAP_IDS.CalendarTourMap}
                    alwaysShowMarkerPositionNumber
                />
            }
            dontCloseOnOutsideClick
        >
            <div className="calendar-tour-map">
                {props.tour.stops.map((stop, i) => (
                    <StopDraftCard
                        key={stop.id}
                        stopDraft={stop}
                        index={i}
                        showPosition
                        onHover={(gId) =>
                            cardHoverHandler({
                                groupId: gId,
                                columnSelector: ".calendar-tour-map",
                            })
                        }
                        hasGroupsAround={checkForGroupsAround({
                            stop,
                            stopAbove: props.tour.stops[i - 1],
                            stopBelow: props.tour.stops[i + 1],
                        })}
                        onClick={() => props.onStopClick(stop)}
                    />
                ))}
                {isEnvironment("staging") && (
                    <>
                        <p
                            style={{
                                whiteSpace: "pre-wrap",
                                display: "flex",
                                flexDirection: "column",
                                gap: 4,
                            }}
                        >
                            <span className="text-base">
                                {t("popup.stopsInfo.packingInstructions")}
                            </span>
                            <span className="text-xs">
                                {FAKE_PACKING_INSTRUCTIONS}
                            </span>
                        </p>
                        <Button
                            variant="secondary"
                            label={t("calendar.printPackingInstructions")}
                            style={{
                                width: "25%",
                                marginTop: 24,
                            }}
                            onClick={printPackingInstructions}
                        />
                    </>
                )}
            </div>
        </Popup>
    );
}

export default CalendarTourMap;
