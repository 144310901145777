import { useQuery } from "@tanstack/react-query";
import { getGooglePlacesAutoComplete } from "../../api/google";
import { GoogleAutoCompleteResponse } from "../../shared/types/google";

function useAddressAutoComplete({
    searchText,
    regionCode,
    enabled = true,
}: {
    searchText: string;
    regionCode?: string;
    enabled?: boolean;
}) {
    const {
        data: predictions,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["address-auto-complete", searchText, regionCode] as const,
        queryFn: async ({ queryKey: [, searchText, regionCode] }) => {
            if (searchText.length < 3) {
                return [];
            }

            const res = await getGooglePlacesAutoComplete(
                searchText,
                regionCode
            );

            if (!res.data.suggestions || res.data.suggestions?.length === 0) {
                return [
                    {
                        placePrediction: {
                            placeId: "no-results",
                            text: {
                                text: searchText,
                            },
                        },
                    } as GoogleAutoCompleteResponse,
                ];
            }

            return res.data.suggestions;
        },
        placeholderData: (prev) => prev,
        enabled: enabled,
    });

    switch (status) {
        case "success":
            return {
                predictions,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                predictions,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                predictions,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useAddressAutoComplete;
