import { useTranslation } from "react-i18next";
import { Order } from "../../../shared/types/api";
import { useMemo } from "react";
import {
    getOrderStatus,
    getOrderStatusTimestamps,
} from "../../../shared/utility/order";
import StatusBadge from "../../UI/StatusBadge";
import { motion } from "framer-motion";
import "./styles.scss";

type Props = {
    order: Order;
};

export default function OrderStatusTimelineNew(props: Props) {
    const { t } = useTranslation();

    const timestamps = useMemo(
        () =>
            getOrderStatusTimestamps(props.order, {
                onlyTime: true,
            }),
        [props.order]
    );

    const progress = useMemo(() => {
        const activeStatus = getOrderStatus(props.order);
        switch (activeStatus) {
            case "created":
                return "26%";
            case "verified":
                return "26%";
            case "at-pickup":
                return "42%";
            case "in-progress":
                return "58%";
            case "next-stop":
                return "74%";
            case "unloading":
                return "90%";
            case "fulfilled":
                return "100%";
            default:
                return "0%";
        }
    }, [props.order]);

    return (
        <div className="order-status-timeline-new">
            <div className="line">
                <motion.div
                    initial={{ width: 0 }}
                    animate={{
                        width: progress,
                        transition: {
                            ease: [0, 0, 0.2, 1],
                            duration: 0.6,
                            delay: 0.4,
                        },
                    }}
                    className="line-foreground"
                />
            </div>
            {Object.entries(timestamps).map(([key, value]) => (
                <StatusBadge
                    key={key}
                    isCompleted={!!value}
                    label={t(`orderInfoPopup.status.${key}`)}
                    timestamp={value}
                />
            ))}
        </div>
    );
}
