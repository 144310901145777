import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { inviteDriver } from "../../api/driver";
import Button from "../../components/buttons/Button";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import PhoneInput from "../../components/inputs/PhoneInput";
import Popup from "../../hoc/Popup";
import useFuelTypes from "../../hooks/data/useFuleTypes";
import { DriverForm, driverForm } from "../../schemas/form";
import { onFormError } from "../../shared/utility/misc";
import { API_ERROR } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
};

function AddDriverPopup(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { fuleTypes, status: fuelTypesStatus } = useFuelTypes();

    const { handleSubmit, control, reset } = useForm<DriverForm>({
        resolver: zodResolver(driverForm),
        defaultValues: {
            phone: "",
            hourlyCost: "",
            fuelTypeId: null,
            enabled: false,
            fuelConsumption: "",
            firstName: "",
            lastName: "",
        },
    });

    const { mutate: inviteDriverHandler, isPending: isInvitingDriver } =
        useMutation({
            mutationFn: async (data: DriverForm) => {
                const formattedFuelConsumption =
                    data.fuelConsumption?.replace(",", ".") || "";
                const formattedHourlyCost =
                    data.hourlyCost?.replace(",", ".") || "";

                await inviteDriver({
                    ...data,
                    fuelConsumption: isNaN(+formattedFuelConsumption)
                        ? "0"
                        : formattedFuelConsumption,
                    hourlyCost: isNaN(+formattedHourlyCost)
                        ? "0"
                        : formattedHourlyCost,
                });
            },
            onSuccess: () => {
                toast.success(t("successMessages.driverAdded"));
                queryClient.invalidateQueries({
                    queryKey: ["drivers"],
                    type: "all",
                });
                props.onClose();
            },
            onError: (error) => {
                if (isAxiosError(error)) {
                    const axiosError = error as AxiosError<{ detail: string }>;
                    if (
                        axiosError.response?.data.detail ===
                        API_ERROR.DriverAlreadyExists
                    ) {
                        toast.error(t("errorMessage.driverAlreadyExists"));
                        return;
                    }
                }
                toast.error(t("errorMessage.unknown"));
            },
        });

    useEffect(() => {
        reset();
    }, [props.showPopup, reset]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.driver.title")}
        >
            <div className="add-driver-popup">
                <Controller
                    key="phone"
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <PhoneInput
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.phoneLabel")}
                            placeholder={t("popup.driver.plusPhonePlaceholder")}
                        />
                    )}
                />
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.firstNameLabel")}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.lastNameLabel")}
                        />
                    )}
                />
                <Controller
                    name="hourlyCost"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.hourlyCostLabel")}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="fuelTypeId"
                    render={({ field: { value, onChange } }) => (
                        <Dropdown
                            value={value?.toString() || null}
                            onSelect={(o) => onChange(+o.value)}
                            label={t("popup.driver.fuleTypeLabel")}
                            options={
                                fuleTypes?.map((f) => ({
                                    value: f.id.toString(),
                                    label: f.name,
                                })) || []
                            }
                            isLoading={fuelTypesStatus === "pending"}
                            isSearchable
                        />
                    )}
                />
                <Controller
                    name="fuelConsumption"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.fuleConsumptionLabel")}
                        />
                    )}
                />
                <Button
                    label={t("popup.driver.submitLabel")}
                    variant="primary"
                    onClick={handleSubmit(
                        (data) => inviteDriverHandler(data),
                        onFormError
                    )}
                    isLoading={isInvitingDriver}
                />
            </div>
        </Popup>
    );
}

export default AddDriverPopup;
