import { faPlus, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/buttons/Button";
import Dropdown from "../../../../components/inputs/Dropdown";
import Input from "../../../../components/inputs/Input";
import RadioGroup from "../../../../components/inputs/RadioGroup";
import Textarea from "../../../../components/inputs/Textarea";
import {
    heightOptions,
    lengthOptions,
    widthOptions,
} from "../../../../shared/values/dropdown";
import { CargoForm } from "../../schemas";
import "../../style.scss";
import { useMemo } from "react";

type Props = {
    control: Control<CargoForm>;
};

function CargoInputForm(props: Props) {
    const { t } = useTranslation();

    const yesNoOptions = useMemo(() => {
        return [
            {
                label: t("general.yes"),
                value: "1",
            },
            {
                label: t("general.no"),
                value: "0",
            },
        ];
    }, [t]);

    return (
        <div className="fleet-manual-order-form">
            <p className="text-sm">{t("fleetManualOrder.cargo.title")}</p>

            <Controller
                control={props.control}
                name="weight"
                render={({ field: { value, onChange } }) => (
                    <Input
                        type="text"
                        value={value === 0 ? "" : value.toString()}
                        onChange={(v) => onChange(+v)}
                        label={t("createTour.weight")}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="weightOfHeaviestPackage"
                render={({ field: { value, onChange } }) => (
                    <Input
                        type="text"
                        value={value === 0 ? "" : value.toString()}
                        onChange={(v) => onChange(+v)}
                        label={t("createOrder.heaviestPackageLabel")}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="numberOfEuPallets"
                render={({ field: { value, onChange } }) => (
                    <Input
                        type="text"
                        value={value === 0 ? "" : value.toString()}
                        onChange={(v) => onChange(+v)}
                        label={t("createOrder.numberOfEuPackagesLabel")}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="dangerousGoods"
                render={({ field: { value, onChange } }) => (
                    <>
                        <RadioGroup
                            value={value ? "1" : "0"}
                            label={t("manualOrder.dangerousGoodsLabel")}
                            labelColor="var(--color-neutral-500)"
                            options={yesNoOptions}
                            onChange={(v) => onChange(v === "1" ? true : false)}
                        />
                        {value ? (
                            <p className="dangerous-goods-warning text-base">
                                {t("manualOrder.cannotBookDangerousGoodsLabel")}
                            </p>
                        ) : null}
                    </>
                )}
            />

            <Controller
                control={props.control}
                name="warmTransport"
                render={({ field: { value, onChange } }) => (
                    <RadioGroup
                        value={value ? "1" : "0"}
                        label={t("manualOrder.warmTransportLabel")}
                        labelColor="var(--color-neutral-500)"
                        options={yesNoOptions}
                        onChange={(v) => onChange(v === "1" ? true : false)}
                    />
                )}
            />

            <Controller
                control={props.control}
                name="stackable"
                render={({ field: { value, onChange } }) => (
                    <RadioGroup
                        value={value ? "1" : "0"}
                        label={t("manualOrder.stackableLabel")}
                        labelColor="var(--color-neutral-500)"
                        options={yesNoOptions}
                        onChange={(v) => onChange(v === "1" ? true : false)}
                    />
                )}
            />

            <Controller
                control={props.control}
                name="dimensions"
                render={({ field: { value, onChange } }) => (
                    <div className="dimensions-wrapper">
                        <p
                            className="text-xs"
                            style={{
                                color: "var(--color-neutral-500)",
                            }}
                        >
                            {t("manualOrder.dimensions")}*
                        </p>
                        {value.map(({ length, width, height }, i) => (
                            <div className="dimension-row" key={i}>
                                <Dropdown
                                    value={length}
                                    onSelect={({ value: newValue }) => {
                                        onChange(
                                            value.map((v, j) =>
                                                j === i
                                                    ? { ...v, length: newValue }
                                                    : v
                                            )
                                        );
                                    }}
                                    options={lengthOptions}
                                    label={t("manualOrder.lengthLabel") + "*"}
                                    width="20%"
                                />
                                <Dropdown
                                    value={width}
                                    onSelect={({ value: newValue }) => {
                                        onChange(
                                            value.map((v, j) =>
                                                j === i
                                                    ? { ...v, width: newValue }
                                                    : v
                                            )
                                        );
                                    }}
                                    options={widthOptions}
                                    label={t("manualOrder.widthLabel") + "*"}
                                    width="20%"
                                />
                                <Dropdown
                                    value={height}
                                    onSelect={({ value: newValue }) => {
                                        onChange(
                                            value.map((v, j) =>
                                                j === i
                                                    ? { ...v, height: newValue }
                                                    : v
                                            )
                                        );
                                    }}
                                    options={heightOptions}
                                    label={t("manualOrder.heightLabel") + "*"}
                                    width="20%"
                                />
                                {i !== 0 ? (
                                    <Button
                                        variant={"secondary"}
                                        leadingIcon={faXmark}
                                        onClick={() =>
                                            onChange(
                                                value.filter((_, j) => j !== i)
                                            )
                                        }
                                    />
                                ) : null}
                            </div>
                        ))}
                        <Button
                            variant={"secondary"}
                            leadingIcon={faPlus}
                            onClick={() =>
                                onChange([
                                    ...value,
                                    {
                                        length: "",
                                        width: "",
                                        height: "",
                                    },
                                ])
                            }
                            style={{
                                width: "20%",
                            }}
                        />
                    </div>
                )}
            />

            <Controller
                control={props.control}
                name="cargoContent"
                render={({ field: { value, onChange } }) => (
                    <Textarea
                        value={value}
                        onChange={onChange}
                        label={t("createOrder.cargoContentLabel") + "*"}
                        textareaStyle={{
                            minHeight: "160px",
                        }}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="driverInstructions"
                render={({ field: { value, onChange } }) => (
                    <Textarea
                        value={value}
                        onChange={onChange}
                        label={t("createTour.driverInstructions")}
                        textareaStyle={{
                            minHeight: "160px",
                        }}
                    />
                )}
            />
        </div>
    );
}

export default CargoInputForm;
