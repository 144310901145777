import { useQuery } from "@tanstack/react-query";
import { getMovedStopDrafts } from "../../api/stop-draft";
import useUserFeatures from "../functionality/useUserFeatures";
import { useSelector } from "react-redux";
import { ReduxState } from "../../shared/types/redux";

function useMovedStops() {
    const features = useUserFeatures();
    const { user } = useSelector((state: ReduxState) => state.auth);

    const {
        data: movedStops,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["moved-stops"] as const,
        queryFn: async () => {
            const res = await getMovedStopDrafts();
            return res.data;
        },
        enabled:
            !!features?.relocate_stop_fleet_planner &&
            !!user?.company_entity?.show_location_orders,
    });

    switch (status) {
        case "success":
            return {
                movedStops,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                movedStops,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                movedStops,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useMovedStops;
