import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CockpitState } from "../../shared/types/redux";
import { updateCockpitStopColumnsLocalStorage } from "../../shared/utility/misc";

const initialState: CockpitState = {
    stopColumns: {
        id: { position: 0, show: true },
        from: { position: 1, show: true },
        to: { position: 2, show: true },
        contact: { position: 3, show: true },
        weight: { position: 4, show: true },
        "dropoff-time": { position: 5, show: true },
        tags: { position: 6, show: true },
        ordernumber: { position: 7, show: false },
        "cargo-content": { position: 8, show: false },
        "driver-instructions": { position: 9, show: false },
    },
};

const cockpitSlice = createSlice({
    name: "cockpit",
    initialState,
    reducers: {
        setStopColumns: (
            state,
            action: PayloadAction<CockpitState["stopColumns"]>
        ) => {
            state.stopColumns = action.payload;
            updateCockpitStopColumnsLocalStorage(action.payload);
        },
        resetStopColumns: (state) => {
            state.stopColumns = initialState.stopColumns;
            updateCockpitStopColumnsLocalStorage(initialState.stopColumns);
        },
    },
});

export const { setStopColumns, resetStopColumns } = cockpitSlice.actions;
export default cockpitSlice.reducer;
