import { isAfter } from "date-fns";
import {
    DK_DEMO_ORDERS,
    GB_DEMO_ORDERS,
    SWE_BIG_VOLUME_ORDERS,
    SWE_DEMO_ORDERS,
} from "../data/demo-data";
import { CreateStopDraftData, StopDraft, StopDraftsTour } from "../types/api";
import {
    DriverStatus,
    KeyNumber,
    KeyString,
    OrderStatus,
    StopStatus,
} from "../types/internal";
import { STOP_DRAFT_TYPE } from "../values/enums";
import { stringToDate } from "./date";
import { getRandomNumber } from "./misc";

export function checkForGroupsAround({
    stop,
    stopAbove,
    stopBelow,
}: {
    stop?: StopDraft;
    stopAbove?: StopDraft;
    stopBelow?: StopDraft;
}) {
    if (!stop) {
        return {
            above: false,
            below: false,
        };
    }

    if (
        stop.motion_tools_stop_group === null ||
        stop.motion_tools_stop_group === undefined
    ) {
        return {
            above: false,
            below: false,
        };
    }

    return {
        above:
            stop.stop_type_id === stopAbove?.stop_type_id &&
            stop.motion_tools_stop_group === stopAbove?.motion_tools_stop_group,

        below:
            stop.stop_type_id === stopBelow?.stop_type_id &&
            stop.motion_tools_stop_group === stopBelow?.motion_tools_stop_group,
    };
}

export function getStopDraftStatus({
    stopDraft,
    orderStatus,
}: {
    stopDraft: StopDraft;
    orderStatus?: OrderStatus;
}) {
    if (!orderStatus) {
        return {
            showStatus: false,
            isStopCompleted: false,
            statusText: "",
            canStopBeMoved: true,
        };
    }

    const statusTextMap = {
        created: "orderHistory.statusCreated",
        verified: "orderHistory.statusVerified",
        "in-progress": "orderHistory.statusInProgress",
        "next-stop": "orderHistory.statusNextStop",
        unloading: "orderHistory.statusUnloading",
        fulfilled: "orderHistory.statusFulfilled",
        "at-pickup":
            stopDraft.stop_type_id === STOP_DRAFT_TYPE.Pickup
                ? "orderHistory.statusAtPickup"
                : "orderHistory.statusVerified",
    };

    const statusText = statusTextMap[orderStatus] || "";

    let showStatus = false;
    let isStopCompleted = false;
    let canStopBeMoved = true;

    const isPickupStop = stopDraft.stop_type_id === STOP_DRAFT_TYPE.Pickup;

    if (isPickupStop) {
        showStatus = ["at-pickup"].includes(orderStatus);
        isStopCompleted = [
            "in-progress",
            "next-stop",
            "unloading",
            "fulfilled",
        ].includes(orderStatus);
    } else {
        showStatus = ["in-progress", "next-stop", "unloading"].includes(
            orderStatus
        );
        isStopCompleted = ["fulfilled"].includes(orderStatus);
    }

    if (isPickupStop) {
        canStopBeMoved = !isStopCompleted && orderStatus !== "at-pickup";
    } else {
        canStopBeMoved = !isStopCompleted && orderStatus !== "unloading";
    }

    return {
        isStopCompleted,
        showStatus,
        statusText,
        canStopBeMoved,
    };
}

export function getStopStatusOLD(stop: StopDraft) {
    const stopStatus = {
        isCompleted: false,
        hasBeenStarted: false,
    };

    if (stop.completed_at) {
        stopStatus.isCompleted = true;
        stopStatus.hasBeenStarted = true;
    }

    if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
        stopStatus.hasBeenStarted = !!stop.order?.pickup_stop_started_at;
    } else {
        stopStatus.hasBeenStarted = !!stop.order?.dropoff_stop_started_at;
    }

    return stopStatus;
}

export function cardHoverHandler({
    groupId,
    columnId,
    columnSelector,
}: {
    groupId: number;
    columnId?: string;
    columnSelector?: string;
}) {
    const column = columnId
        ? document.querySelector(`[data-column-id="${columnId}"]`)
        : columnSelector
        ? document.querySelector(columnSelector)
        : null;

    if (!column) return;

    const cards = column.querySelectorAll<HTMLElement>(
        ".stop-draft-card, .stop-card"
    );

    for (let i = 0; i < cards.length; i++) {
        const card = cards[i];

        if (groupId === -1) {
            card.classList.remove("connected");
            card.classList.remove("not-connected");
            continue;
        }

        if (card.getAttribute("data-group-id") !== groupId.toString()) {
            card.classList.remove("connected");
            card.classList.add("not-connected");
        } else {
            card.classList.add("connected");
            card.classList.remove("not-connected");
        }
    }
}

export function generateDemoStopDrafts(demoOptions?: {
    from?: string[];
    to?: string[];
    region?: string;
    bigVolume?: boolean;
    noDate?: boolean;
    customersIds?: number[];
}) {
    let orders = SWE_DEMO_ORDERS;

    if (demoOptions?.region === "GB") {
        orders = GB_DEMO_ORDERS;
    }

    if (demoOptions?.region === "DK") {
        orders = DK_DEMO_ORDERS;
    }

    if (demoOptions?.bigVolume) {
        orders = SWE_BIG_VOLUME_ORDERS;
    }

    const result: {
        pickup: CreateStopDraftData;
        dropoff: CreateStopDraftData;
    }[] = [];

    for (let i = 0; i < orders.length; i++) {
        const order = orders[i];

        const randomFrom =
            demoOptions?.from?.[getRandomNumber(demoOptions.from.length - 1)];
        const randomTo =
            demoOptions?.to?.[getRandomNumber(demoOptions.to.length - 1)];

        const customerIdAtDropoff =
            demoOptions?.customersIds?.[order.customerAtDropoff];

        const pickup: CreateStopDraftData = {
            to_location: randomFrom || order.from,
            phone: "",
            cargo_content: order.cargoContent,
            estimated_offload_time: 15,
            order_number: order.orderNumber.toString(),
            stop_type_id: STOP_DRAFT_TYPE.Pickup,
            time_tooltip: order.time,
            date_tooltip: demoOptions?.noDate ? null : order.date,
            weight_kg: order.weight,
            tags: [order.tag],
        };

        const dropoff: CreateStopDraftData = {
            to_location: randomTo || order.to,
            phone: "",
            cargo_content: order.cargoContent,
            estimated_offload_time: 15,
            order_number: order.orderNumber.toString(),
            stop_type_id: STOP_DRAFT_TYPE.Dropoff,
            time_tooltip: order.time,
            date_tooltip: demoOptions?.noDate ? null : order.date,
            weight_kg: order.weight,
            tags: [order.tag],
            contact_id: customerIdAtDropoff,
        };

        result.push({
            pickup,
            dropoff,
        });
    }

    return result;
}

export function getTourStatus(tour: StopDraftsTour) {
    const hasTourBeenStarted = tour.stops.some(
        (stop) => !!stop.order?.pickup_stop_started_at
    );

    const isUndispatched =
        tour.dispatched_at === null && tour.updated_at !== null;

    const hasUndispatchedChanges =
        tour.dispatched_at && tour.updated_at
            ? isAfter(
                  stringToDate(tour.updated_at)!,
                  stringToDate(tour.dispatched_at)!
              )
            : false;

    const hideEta =
        hasUndispatchedChanges || isUndispatched || !hasTourBeenStarted;

    return {
        hasTourBeenStarted,
        isUndispatched,
        hasUndispatchedChanges,
        hideEta,
    };
}

export function getStopListRealtiveEtaPerIdInMinutes(
    stops: StopDraft[],
    legsDurationInMinutes?: number[]
) {
    const durationMap: KeyNumber<number> = {};

    for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];
        const previousStop: StopDraft | null = stops[i - 1] || null;

        if (!previousStop) {
            durationMap[stop.id] = 0;
            continue;
        }

        const previousRealtiveEta = durationMap[previousStop.id];

        let estimatedTimeToNextStop = 0;
        if (legsDurationInMinutes) {
            estimatedTimeToNextStop = legsDurationInMinutes[i - 1] || 0;
        }
        if (previousStop?.estimated_time_to_next_stop) {
            estimatedTimeToNextStop = previousStop.estimated_time_to_next_stop;
        }

        let offloadTime = 0;

        if (previousStop.motion_tools_stop_group) {
            if (
                previousStop?.motion_tools_stop_group ===
                stop.motion_tools_stop_group
            ) {
                offloadTime = 0;
            } else {
                const allGroupOffloadTimes = stops
                    .filter(
                        (s) =>
                            s.motion_tools_stop_group ===
                            previousStop.motion_tools_stop_group
                    )
                    .map((s) => s.estimated_offload_time || 0);

                const highestOffloadTime = Math.max(...allGroupOffloadTimes);

                offloadTime = highestOffloadTime;
            }
        } else {
            offloadTime = previousStop.estimated_offload_time || 0;
        }

        if (
            offloadTime &&
            previousStop.stop_type_id === STOP_DRAFT_TYPE.Pickup
        ) {
            offloadTime = offloadTime / 2;
        }

        durationMap[stops[i].id] =
            previousRealtiveEta + estimatedTimeToNextStop + offloadTime;
    }

    return durationMap;
}

export function getStopListOffloadDurationInMinutes(stops: StopDraft[]) {
    return stops.reduce((acc, stop, index, stops) => {
        const previousStop = stops[index - 1];

        let offloadTime = 0;

        if (stop.motion_tools_stop_group) {
            if (
                previousStop?.motion_tools_stop_group ===
                stop.motion_tools_stop_group
            ) {
                offloadTime = 0;
            } else {
                const allGroupOffloadTimes = stops
                    .filter(
                        (s) =>
                            s.motion_tools_stop_group ===
                            stop.motion_tools_stop_group
                    )
                    .map((s) => s.estimated_offload_time || 0);

                const highestOffloadTime = Math.max(...allGroupOffloadTimes);

                offloadTime = highestOffloadTime;
            }
        } else {
            offloadTime = stop.estimated_offload_time || 0;
        }

        if (offloadTime && stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
            offloadTime = offloadTime / 2;
        }

        return acc + offloadTime;
    }, 0);
}

export function getStopListDrivingDurationInMinutes(stops: StopDraft[]) {
    return stops.reduce((acc, stop) => {
        const estimatedTimeToNextStop = stop.estimated_time_to_next_stop || 0;

        return acc + estimatedTimeToNextStop;
    }, 0);
}

export function getStopListDurationInMinutes(stops: StopDraft[]) {
    const totalOffloadTime = getStopListOffloadDurationInMinutes(stops);
    const totalDrivingTime = getStopListDrivingDurationInMinutes(stops);

    return totalOffloadTime + totalDrivingTime;
}

export function getRunningWeight(stops: StopDraft[]) {
    const weights: number[] = [];

    if (stops.every((stop) => !stop.weight_kg)) {
        return {
            peakWeight: 0,
            runningWeights: [],
        };
    }

    for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];

        const currentWeight = weights[i - 1] || 0;

        if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
            weights.push(currentWeight + (stop.weight_kg || 0));
        } else {
            weights.push(currentWeight - (stop.weight_kg || 0));
        }
    }

    return {
        peakWeight: Math.max(...weights),
        runningWeights: weights,
    };
}

export function getCombinedStops(stops: StopDraft[]) {
    const combinedStops: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }[] = [];

    let pickups = stops.filter(
        (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
    );

    /*if (options.noExternalStops) {
        pickups = pickups.filter((pickup) => !pickup.external);
    }

    if (options.noStopRequests) {
        pickups = pickups.filter((pickup) => {
            const isAsssignedFromOtherLocation = pickup.origin_location;
            const isStopAccepted = !!pickup.move_accepted_at;

            return isAsssignedFromOtherLocation ? isStopAccepted : true;
        });
    }

    if (options.noUnhandledStops) {
        pickups = pickups.filter((pickup) => {
            const isExternal = !!pickup.external;
            const isAsssignedFromOtherLocation = pickup.origin_location;
            const isStopAccepted = !!pickup.move_accepted_at;

            return (
                isExternal || (isAsssignedFromOtherLocation && !isStopAccepted)
            );
        });
    }

    if (options.filterDate) {
        pickups = pickups.filter((pickup) =>
            pickup.date_tooltip
                ? pickup.date_tooltip === dateToString(options.filterDate)
                : true
        );
    }*/

    for (let i = 0; i < pickups.length; i++) {
        const pickup = pickups[i];

        const dropoff = stops.find(
            (stop) =>
                stop.group_id === pickup.group_id &&
                stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
        );

        if (!dropoff) continue;

        combinedStops.push({
            pickup,
            dropoff,
        });
    }

    return combinedStops;
}

export function getStopLocationText(stop: StopDraft) {
    if (stop.city && stop.zip_code) return stop.city + ", " + stop.zip_code;
    if (stop.city) return stop.city;
    if (stop.zip_code) return stop.zip_code;

    return stop.to_location;
}

export function getStopOrderMap(stops: StopDraft[]) {
    const stopOrderMap: KeyString<number> = {};

    for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];

        //If its the first stop the order is always 1
        if (i === 0) {
            stopOrderMap[
                stop.motion_tools_stop_group || stop.id.toString()
            ] = 0;
            continue;
        }

        const stopsAbove = stops.filter((s, j) => j < i);
        const groupedStops = stopsAbove.filter(
            (s) => !!s.motion_tools_stop_group
        );
        const amountOfUniqueGroups = new Set(
            groupedStops.map((s) => s.motion_tools_stop_group)
        ).size;

        if (
            stop.motion_tools_stop_group &&
            stop.motion_tools_stop_group in stopOrderMap
        )
            continue;

        stopOrderMap[stop.motion_tools_stop_group || stop.id.toString()] =
            i - (groupedStops.length - amountOfUniqueGroups);
    }

    return stopOrderMap;
}

export function getStopStatus(stop: StopDraft) {
    let stopStatus: StopStatus = "planned";

    if (
        stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
        stop.order?.pickup_stop_started_at
    ) {
        stopStatus = "started";
    }
    if (
        stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
        stop.order?.dropoff_stop_started_at
    ) {
        stopStatus = "started";
    }

    if (stop.arrived_at) {
        if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
            stopStatus = "loading";
        }
        if (stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff) {
            stopStatus = "unloading";
        }
    }
    if (stop.completed_at) {
        stopStatus = "completed";
    }

    return stopStatus;
}

export function getDriverStatus(
    currentStop?: StopDraft,
    previousStop?: StopDraft
) {
    let driverStatus: DriverStatus = "available";

    if (!currentStop) return driverStatus;

    const currentStopStatus = getStopStatus(currentStop);

    if (currentStopStatus === "started") {
        if (currentStop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
            driverStatus = "driving-pickup";
        }

        if (currentStop.stop_type_id === STOP_DRAFT_TYPE.Dropoff) {
            driverStatus = "driving-dropoff";
        }
    }

    if (currentStopStatus === "loading") {
        driverStatus = "loading";
    }

    if (currentStopStatus === "unloading") {
        driverStatus = "unloading";
    }

    // For the case when the driver has completed the previous stop and not yet started the next stop. (happens if the driver stops at the pod screen in the app)
    if (previousStop && currentStopStatus === "planned") {
        const previousStopStatus = getStopStatus(previousStop);
        if (previousStopStatus === "completed") {
            driverStatus =
                previousStop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                    ? "loading"
                    : "unloading";
        }
    }

    return driverStatus;
}
