import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import "./style.scss";

type Props = {
    title?: string;
    description?: string;
    statusIcon?: {
        icon: IconDefinition;
        color: string;
    };
    statusLabel?: string;
    description2?: string;
    actions?: {
        onClick: (event: React.MouseEvent) => void;
        label: string;
        hide?: boolean;
    }[];
};

function MapInfoWindow(props: Props) {
    const motionVariants = {
        initial: { opacity: 0, y: 10, scale: 0.5 },
        enter: { opacity: 1, y: 0, scale: 1 },
    };
    return (
        <div className="map-info-window-wrapper">
            <motion.div
                className="map-info-window"
                variants={motionVariants}
                initial="initial"
                animate="enter"
                exit="initial"
            >
                <div className="top">
                    {props.title && (
                        <p
                            className="text-2xs"
                            style={{
                                color: "var(--color-neutral-600)",
                            }}
                        >
                            {props.title}
                        </p>
                    )}
                    <div className="horizontal-align">
                        {props.description && (
                            <p className="text-sm description">
                                {props.description}
                            </p>
                        )}
                        {props.description2 && (
                            <p
                                className="text-xs"
                                style={{
                                    color: "var(--color-neutral-400)",
                                    textWrap: "nowrap",
                                }}
                            >
                                {props.description2}
                            </p>
                        )}
                    </div>
                </div>
                {props.statusIcon && (
                    <div className="status-wrapper">
                        <FontAwesomeIcon
                            icon={props.statusIcon.icon}
                            size="sm"
                            color={props.statusIcon.color}
                        />
                        <p>{props.statusLabel}</p>
                    </div>
                )}
                {props.actions &&
                    props.actions
                        .filter((a) => !a.hide)
                        .map((action) => (
                            <button
                                key={action.label}
                                type="button"
                                className="subtle-button"
                                onClick={action.onClick}
                            >
                                {action.label}
                            </button>
                        ))}
            </motion.div>
        </div>
    );
}

export default MapInfoWindow;
