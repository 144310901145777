import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Tooltip from "../Tooltip";
import "./style.scss";

type Props = {
    label: string;
    color?: string;
    isCompleted?: boolean;
    hideTooltip?: boolean;
    groupedAmount?: number;
    tooltipData?: {
        address: string;
        status?: string;
        iconColor?: string;
        cargoContent?: string;
        alignment?: "top" | "bottom";
    };
    iconColor?: string;
};

function StopCardIcon(props: Props) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className="stop-card-icon-wrapper"
            style={{ position: "relative" }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <AnimatePresence>
                {showTooltip && props.tooltipData && !props.hideTooltip && (
                    <Tooltip
                        style={
                            props.tooltipData.alignment === "bottom"
                                ? {
                                      top: "100%",
                                      bottom: "auto",
                                  }
                                : undefined
                        }
                    >
                        <div className="stop-card-tooltip">
                            <p className="text-sm address">
                                {props.tooltipData.address}
                            </p>
                            {props.tooltipData.status && (
                                <div className="status">
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        size="xs"
                                        color={props.tooltipData.iconColor}
                                    />

                                    {props.tooltipData.status && (
                                        <p className="text-xs">
                                            {props.tooltipData.status}
                                        </p>
                                    )}
                                </div>
                            )}
                            {props.tooltipData.cargoContent && (
                                <p
                                    className="text-xs"
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {props.tooltipData.cargoContent
                                        .split("")
                                        .map((content, index) =>
                                            content === "\n" ? (
                                                <br key={index} />
                                            ) : (
                                                content
                                            )
                                        )}
                                </p>
                            )}
                        </div>
                    </Tooltip>
                )}
            </AnimatePresence>
            <motion.div
                whileHover={props.hideTooltip ? {} : { scale: 1.2 }}
                className="stop-card-icon"
                style={
                    props.color
                        ? { backgroundColor: props.color }
                        : { backgroundColor: "var(--color-neutral-100)" }
                }
            >
                {!!props.groupedAmount && (
                    <div className="grouped-amount">
                        <span className="text-2xs">{props.groupedAmount}</span>
                    </div>
                )}
                {props.iconColor && !props.isCompleted && (
                    <div className="status">
                        <FontAwesomeIcon
                            icon={faCircle}
                            size="xs"
                            color={props.iconColor}
                        />
                    </div>
                )}
                {props.isCompleted ? (
                    <FontAwesomeIcon
                        icon={faCheck}
                        color="var(--color-pure-white)"
                    />
                ) : (
                    <p className="text-xs">{props.label}</p>
                )}
            </motion.div>
        </div>
    );
}

export default StopCardIcon;
