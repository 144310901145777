import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useMemo } from "react";
import useDirections from "../../../hooks/functionality/useDirections";
import { StopDraft, StopDraftsTour } from "../../../shared/types/api";
import {
    calculateSpiral,
    getLatLngCountMap,
    getLocationKey,
} from "../../../shared/utility/misc";
import {
    getStopOrderMap,
    getStopStatus,
} from "../../../shared/utility/stop-draft";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import "./style.scss";

type Props = {
    tour?: StopDraftsTour;
    stops?: StopDraft[];
    hoveredStopId?: number | null;
    tourColor?: {
        primary: string;
        accent: string;
    };
    disableDirections?: boolean;
    alwaysShowMarkerPositionNumber?: boolean;
};

function TourMarkers(props: Props) {
    const stopOrderMap = useMemo(() => {
        const stopList = props.stops || props.tour?.stops;
        if (!stopList) return {};
        return getStopOrderMap(stopList);
    }, [props.stops, props.tour?.stops]);

    const mapPoints = useMemo(() => {
        const stopList = props.stops || props.tour?.stops;

        if (!stopList) return [];

        return stopList.map((stop, i) => {
            const stopStatus = getStopStatus(stop);
            const stopLabel =
                stopOrderMap[
                    stop.motion_tools_stop_group || stop.id.toString()
                ] + 1;

            return {
                id: stop.id,
                label: stopLabel,
                location: {
                    lat: +stop.lat!,
                    lng: +stop.lng!,
                },
                stopStatus,
            };
        });
    }, [props.stops, props.tour?.stops, stopOrderMap]);

    const uniqueMapPoints = useMemo(() => {
        const foundPointLabels = new Set();
        return mapPoints.filter((point) => {
            if (foundPointLabels.has(point.label)) return false;
            foundPointLabels.add(point.label);
            return true;
        });
    }, [mapPoints]);

    const { completedPoints, incompletedPoints } = useMemo(() => {
        const completedPoints = mapPoints.filter(
            (point) => point.stopStatus === "completed"
        );
        const incompletePoints = mapPoints.filter(
            (point) => point.stopStatus !== "completed"
        );
        const lastCompletedPoint = completedPoints[completedPoints.length - 1];

        if (lastCompletedPoint) {
            incompletePoints.unshift(lastCompletedPoint);
        }
        return {
            completedPoints: completedPoints.map((point) => point.location),
            incompletedPoints: incompletePoints.map((point) => point.location),
        };
    }, [mapPoints]);

    useDirections({
        path: incompletedPoints,
        directionsColor: props.tourColor?.primary,
        hide: props.disableDirections,
        zIndex: 2,
    });

    useDirections({
        path: completedPoints,
        directionsColor: props.tourColor?.accent,
        hide: props.disableDirections,
        zIndex: 1,
    });

    const latLngStopCounts = useMemo(
        () => getLatLngCountMap(mapPoints.map((point) => point.location)),
        [mapPoints]
    );

    return (
        <>
            {uniqueMapPoints.map((point, i) => (
                <AdvancedMarker
                    key={i}
                    position={
                        latLngStopCounts[getLocationKey(point.location)] > 1
                            ? calculateSpiral(point.location, i)
                            : point.location
                    }
                    zIndex={
                        point.id === props.hoveredStopId
                            ? Number.MAX_SAFE_INTEGER
                            : point.stopStatus === "completed"
                            ? undefined
                            : mapPoints.length - i
                    }
                >
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={{
                            hidden: { opacity: 0, scale: 0.5 },
                            visible: {
                                opacity: 1,
                                scale:
                                    point.id === props.hoveredStopId ? 1.3 : 1,
                                y: point.id === props.hoveredStopId ? -5 : 0,
                            },
                        }}
                        exit="hidden"
                        className="tour-marker"
                        style={{
                            backgroundColor:
                                point.stopStatus === "completed"
                                    ? props.tourColor?.accent
                                    : props.tourColor?.primary,
                            boxShadow:
                                point.stopStatus === "completed"
                                    ? "none"
                                    : "var(--shadow-lg)",
                        }}
                    >
                        {point.stopStatus === "completed" &&
                        !props.alwaysShowMarkerPositionNumber ? (
                            <FontAwesomeIcon icon={faCheck} size="sm" />
                        ) : (
                            <span className="text-2xs">{point.label}</span>
                        )}
                    </motion.div>
                </AdvancedMarker>
            ))}
        </>
    );
}

export default TourMarkers;
